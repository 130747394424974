import CourseService from '../../services/courseService';
import { showSnackbar } from './snackbarActions';
import { ERROR } from '../../snackbar/successSnackbar';

import { DEPENDENCY } from '../../services/errors';

/*
 * Action Types
 */
export const ADD_TERM = 'ADD_TERM';
export const ADD_TERM_SUCCESS = 'ADD_TERM_SUCCESS';
export const UPDATE_TERM_SUCCESS = 'UPDATE_TERM_SUCCESS';

export const DELETE_TERM = 'DELETE_TERM';
export const DELETE_TERM_SUCCESS = 'DELETE_TERM_SUCCESS';

export const GET_TERM_BY_ID = 'GET_TERM_BY_ID';

export const GET_ALL_TERMS = 'GET_ALL_TERM';
export const GET_ALL_TERMS_SUCCESS = 'GET_ALL_TERM_SUCCESS';

/*
 * Action creators
 */
export const deleteTermSuccess = (term) => ({ type: DELETE_TERM_SUCCESS, term });
export const deleteTerm = (term) => (dispatch) => CourseService.deleteTerm(term).then(() => {
  dispatch(showSnackbar('Successfully deleted term.'));
  dispatch(deleteTermSuccess(term));
}).catch((error) => {
  if (error.type === DEPENDENCY) {
    dispatch(showSnackbar('Can not delete term. Make sure no other courses are dependent on this term.', ERROR));
  }
  // throw error
});

export const getTermById = (id) => ({ type: GET_TERM_BY_ID, id });

export const addTermSuccess = (term) => ({ type: ADD_TERM_SUCCESS, term });
export const updateTermSuccess = (term) => ({ type: UPDATE_TERM_SUCCESS, term });
export const saveTerm = (term) => (dispatch) => {
  if (!term.id) {
    return CourseService.addTerm(term).then((newTerm) => {
      dispatch(showSnackbar('Successfully added term.'));
      dispatch(addTermSuccess(newTerm));
    }).catch((error) => {
      throw error;
    });
  }
  return CourseService.updateTerm(term).then(() => {
    dispatch(showSnackbar('Successfully updated term.'));
    dispatch(updateTermSuccess(term));
  }).catch((error) => {
    throw error;
  });
};

export const getAllTermsSuccess = (terms) => ({ type: GET_ALL_TERMS_SUCCESS, terms });
export const getAllTerms = () => (dispatch) => CourseService.getAllTerms().then((terms) => {
  dispatch(getAllTermsSuccess(terms));
}).catch((error) => {
  throw error;
});
