import { db, storage } from '../firebase';

import RegistrationService from './registrationService';

import { DEPENDENCY } from './errors';
import CourseService from './courseService';
import InvoiceService from './invoiceService';

const getAllGroups = () =>
  new Promise((resolve) => {
    db.collection('groups').onSnapshot((snapshot) => {
      const allGroups = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      resolve(allGroups);
    });
  });

const getGroupsByPerson = (person) =>
  new Promise((resolve) => {
    db.collection('groups')
      .where('teacherId', '==', person.id)
      .onSnapshot((snapshot) => {
        const allCourses = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        resolve(allCourses || []);
      });
  });

function makeid(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;

  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

const addGroup = (group) => {
  delete group.id;

  return new Promise((resolve) => {
    // TODO ensure uniqueness
    group.code = makeid(6);
    group.active = true;

    db.collection('groups')
      .add(group)
      .then((g) => {
        group.id = g.id;

        resolve(group);
      });
  });
};

const updateGroup = (group) =>
  new Promise((resolve) => {
    if (
      group.posts &&
      group.posts.length > 0 &&
      group.posts[0].attachments &&
      group.posts[0].attachments.length === 1 &&
      group.posts[0].attachments[0] === ''
    ) {
      group.posts[0].attachments.pop();
    }

    if (
      group.posts &&
      group.posts.length > 0 &&
      group.posts[0].attachments &&
      group.posts[0].attachments.length > 0 &&
      typeof group.posts[0].attachments[0] !== 'string' &&
      group.posts[0].attachments[0].status !== 'saved attachment'
    ) {
      const post = group.posts[0];
      const attachment = post.attachments[0];
      const imageName = attachment.name;

      const uploadTask = storage
        .ref(`/group_files/${group.id}/${imageName}`)
        .put(attachment);
      uploadTask.on(
        'state_changed',
        (snapShot) => {
          // takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          // catches the errors
          console.log(err);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref(`/group_files/${group.id}`)
            .child(imageName)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              post.attachments[0] = {
                name: imageName,
                path: fireBaseUrl,
                status: 'saved attachment',
              };

              db.collection('groups')
                .doc(group.id)
                .set(group)
                .then(() => {
                  resolve(group);
                });
            });
        }
      );
    } else {
      db.collection('groups')
        .doc(group.id)
        .set(group)
        .then(() => {
          resolve(group);
        });
    }
  });

const deleteGroup = (group) =>
  new Promise((resolve, reject) => {
    RegistrationService.getRegistrationsByGroup(group).then((registrations) => {
      if (registrations.length > 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ type: DEPENDENCY, registrations });
      } else {
        db.collection('groups')
          .doc(group.id)
          .delete()
          .then(() => {
            resolve(group);
          });
      }
    });
  });

const invoiceGroup = (group) =>
  new Promise((resolve) => {
    InvoiceService.getAllInvoices().then((allInvoices) => {
      RegistrationService.getRegistrationsByGroup(group).then(
        (allRegistrations) => {
          if (allRegistrations.length > 0) {
            const { courseId } = allRegistrations[0];

            const registrations = allRegistrations.filter((reg) => {
              const invoice = allInvoices.find(
                (i) => i.items[0].registrationId === reg.id
              );

              if (invoice) {
                const isValid = invoice.hasOwnProperty('stripeInvoiceId');

                if (!isValid) {
                  InvoiceService.deleteInvoice(invoice).then(() => {
                    console.log('Deleted orphaned invoice');
                  });
                  return true;
                }
              }

              return !invoice;
            });

            CourseService.getAllCourses().then((courses) => {
              const course = courses.find((c) => c.id === courseId);

              if (course) {
                const invoices = [];

                registrations.forEach((reg) => {
                  const invoice = {
                    email: reg.email.trim(),
                    items: [
                      {
                        amount: Number(course.price) * 100,
                        currency: 'usd',
                        description: `${course.name} Registration: ${reg.studentFirstName} ${reg.studentLastName}`,
                        registrationId: reg.id,
                        courseId: course.id,
                      },
                    ],
                  };

                  if (invoice.amount > 0) {
                    invoices.push(invoice);
                    console.log('Added invoice:', invoice);
                  } else {
                    console.log('Free course. No invoice needed!');
                  }
                });

                resolve(invoices);
              }
            });
          }
        }
      );
    });
  });

const GroupService = {
  getAllGroups,
  addGroup,
  updateGroup,
  deleteGroup,
  getGroupsByPerson,
  invoiceGroup,
};

export default GroupService;
