import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { EditorState } from 'draft-js';

import {
  Button, IconButton, Typography, Box,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import MUIRichTextEditor from 'mui-rte';
import { stateToHTML } from 'draft-js-export-html';

import { saveGroup as saveGroupAction } from '../../redux/actions/groupActions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  groupForm: {
    paddingRight: 30,
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
  fileInput: {
    display: 'none',
  },
  uploadButton: {
    margin: '20px 0 0 20px',
  },
}));

const AddPostComponent = ({
  onClose, group, saveGroup, currentUser,
}) => {
  const classes = useStyles();

  const [file, setFile] = useState('');

  const [state, setState] = useState({
    editorState: EditorState.createEmpty(),
  });

  const handleSaveClick = () => {
    const html = stateToHTML(state.editorState.getCurrentContent());

    if (!group.posts) {
      group.posts = [];
    }

    const post = {
      content: html,
      postDate: Date.now(),
      postAuthor: `${currentUser.firstName} ${currentUser.lastName}`,
      attachments: [file],
    };

    group.posts.unshift(post);

    saveGroup(group).then(() => onClose());
  };

  function handleEditorChanged(editorState) {
    setState({
      editorState,
    });
  }

  return (
    <Box>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Tooltip title="Close without saving">
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" className={classes.title}>Post To Group</Typography>
          <Tooltip title="Save and close">
            <Button
              autoFocus
              color="inherit"
              onClick={handleSaveClick}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <input
        accept="image/*, application/pdf, video/*, audio/*"
        className={classes.fileInput}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
      />

      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="default"
          component="span"
          className={classes.uploadButton}
          startIcon={<CloudUploadIcon />}
        >
          Attach File
        </Button>

        { file ? file.name : '' }
      </label>

      <div className={classes.groupForm}>
        <MUIRichTextEditor
          editorState={state.editorState}
          controls={['bold', 'italic', 'underline', 'undo', 'redo', 'link', 'media', 'numberList', 'bulletList', 'quote']}
          label="Start typing..."
          onChange={handleEditorChanged}
          fullWidth
        />
      </div>
    </Box>
  );
};

AddPostComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  group: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  saveGroup: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ auth: { currentUser } }, { group }) => ({
  group,
  currentUser,
});

const mapDispatchToProps = {
  saveGroup: saveGroupAction,
};

const AddPost = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPostComponent));

export default AddPost;
