import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

const InputTextField = ({
  value, placeholder, required, handleChange,
}) => (
  <>
    <TextField
      value={value}
      label={placeholder}
      required={required}
      onChange={handleChange}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
    />
  </>
);

InputTextField.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default InputTextField;
