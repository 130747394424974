import React, { useState, useEffect } from 'react';
import {
  FormControl, InputLabel, Select, TextField, FormControlLabel, Checkbox, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { TEXT_FIELD, DROP_DOWN, AVAILABILITY } from '../../forms/dynamicForm';

const FormFieldConfig = ({ field, deleteField }) => {
  const [type, setType] = useState(TEXT_FIELD);
  const [placeholder, setPlaceholder] = useState('');
  const [required, setRequired] = useState(false);
  const [values, setValues] = useState('');

  useEffect(() => {
    setType(field.type || TEXT_FIELD);
    setPlaceholder(field.placeholder || '');
    setRequired(field.required || false);
    setValues(field.values || '');
  }, [field]);

  const updateField = () => {
    field.type = type;
    field.placeholder = field.type === AVAILABILITY ? 'Availability' : placeholder;
    field.required = required;
    field.values = values;
  };

  return (
    <>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
        <Select
          native
          value={type}
          onChange={(e) => setType(e.target.value)}
          onBlur={() => updateField()}
          label="Type"
          required
          inputProps={{
            name: 'type',
            id: 'outlined-age-native-simple',
          }}
        >
          <option value={TEXT_FIELD} key="Text">Text</option>
          <option value={DROP_DOWN} key="Select">Select</option>
          <option value={AVAILABILITY} key="Availability">Availability</option>
        </Select>
      </FormControl>
      {type !== AVAILABILITY ? (
        <TextField
          id="placeholder"
          value={placeholder}
          onChange={(e) => setPlaceholder(e.target.value)}
          onBlur={() => updateField()}
          label="Field Name"
          variant="outlined"
        />
      ) : ''}

      {
                type === DROP_DOWN ? (
                  <TextField
                    id="values"
                    value={values}
                    onChange={(e) => setValues(e.target.value)}
                    onBlur={() => updateField()}
                    label="Values"
                    variant="outlined"
                  />
                ) : (<></>)
            }
      {type !== AVAILABILITY ? (
        <FormControlLabel
          control={(
            <Checkbox
              checked={required}
              onChange={(e) => setRequired(!required)}
              onBlur={() => updateField()}
              name="required"
              color="primary"
            />
                      )}
          label="Required"
        />
      ) : ''}
      <IconButton aria-label="delete" color="primary" onClick={() => deleteField(field)}>
        <DeleteIcon />
      </IconButton>
    </>
  );
};

export default FormFieldConfig;
