import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { TextField, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { saveForm } from '../../redux/actions/formActions';

import '../../formStyles.scss';
import { TEXT_FIELD } from '../../forms/dynamicForm';
import FormFieldConfig from './formFieldConfig';

const AddFormComponent = ({ saveForm, form, history }) => {
  const [name, setName] = useState('');
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (form) {
      setName(form.name);
      setFields(form.fields);
    }
  }, [form]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const f = {
      name,
      fields,
    };

    if (form) {
      f.id = form.id;
    }

    saveForm(f).then(() => history.push('/dashboard/view-forms'));
  };

  const addField = () => {
    const newField = {
      type: TEXT_FIELD,
      placeholder: '',
      required: false,
    };

    setFields([...fields, newField]);
  };

  const deleteField = (field) => {
    setFields(fields.filter((f) => f !== field));
  };

  return (
    <main>
      <h2>Add Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="formElement">
          <TextField
            id="name"
            label="Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>

        {
                    fields.map((field) => <div key={Math.random()}><FormFieldConfig field={field} deleteField={deleteField} /></div>)
                }

        <div><Button type="button" color="secondary" onClick={addField}>+ Add Field</Button></div>
        <div><Button type="submit" variant="contained" color="primary">Submit</Button></div>
      </form>
    </main>
  );
};

AddFormComponent.propTypes = {
  saveForm: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ forms: { forms } }, ownProps) => {
  const formId = ownProps.match.params.id;

  return {
    form: forms.find((form) => form.id === formId) || null,
  };
};

const mapDispatchToProps = {
  saveForm,
};

const AddForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFormComponent));

export default AddForm;
