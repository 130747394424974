import CourseService from '../../services/courseService';

/*
 * Action Types
 */
export const ADD_PROGRAM = 'ADD_PROGRAM';
export const EDIT_PROGRAM = 'EDIT_PROGRAM';
export const DELETE_PROGRAM = 'DELETE_PROGRAM';
export const GET_PROGRAM_BY_ID = 'GET_PROGRAM_BY_ID';
export const GET_PROGRAM_BY_SLUG = 'GET_PROGRAM_BY_SLUG';

export const GET_ALL_PROGRAMS = 'GET_ALL_PROGRAMS';
export const GET_ALL_PROGRAMS_SUCCESS = 'GET_ALL_PROGRAMS_SUCCESS';

/*
 * Action creators
 */

export const addProgram = (program) => ({ type: ADD_PROGRAM, program });
export const editProgram = (program) => ({ type: EDIT_PROGRAM, program });
export const deleteProgram = (program) => ({ type: DELETE_PROGRAM, program });

export const getProgramById = (id) => ({ type: GET_PROGRAM_BY_ID, id });
export const getProgramBySlug = (slug) => ({ type: GET_PROGRAM_BY_SLUG, slug });

export const getAllProgramsSuccess = (programs) => ({ type: GET_ALL_PROGRAMS_SUCCESS, programs });
export const getAllPrograms = () => (dispatch) => CourseService.getAllPrograms().then((programs) => {
  dispatch(getAllProgramsSuccess(programs));
}).catch((error) => {
  throw error;
});
