import { db } from '../firebase';
import CourseService from './courseService';

import { DEPENDENCY } from './errors';

const getAllForms = () => new Promise((resolve, reject) => {
  db.collection('forms').onSnapshot((snapshot) => {
    const allForms = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    resolve(allForms);
  });
});

const addForm = (form) => {
  console.log('form=', form);
  form.fields = form.fields.map((field) => ({
    ...field,
    name: toCamelCaseString(field.placeholder),
  }));

  return new Promise((resolve, reject) => {
    db.collection('forms')
      .add(form)
      .then((t) => {
        form.id = t.id;

        resolve(form);
      });
  });
};

const updateForm = (form) => {
  console.log('form=', form);
  form.fields = form.fields.map((field) => ({
    ...field,
    name: toCamelCaseString(field.placeholder),
  }));

  return new Promise((resolve, reject) => {
    db.collection('forms')
      .doc(form.id)
      .set(form)
      .then((t) => {
        resolve(form);
      });
  });
};

const deleteForm = (form) => new Promise((resolve, reject) => {
  CourseService.getCoursesByForm(form).then((courses) => {
    // Ensure there are no courses associated with the term
    if (courses.length > 0) {
      reject({ type: DEPENDENCY, courses });
    } else {
      db.collection('forms')
        .doc(form.id)
        .delete()
        .then(() => {
          resolve(form);
        });
    }
  });
});

const convertToString = (input) => {
  if (input) {
    if (typeof input === 'string') {
      return input;
    }

    return String(input);
  }

  return '';
};

const toWords = (input) => {
  input = convertToString(input);

  const regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;

  return input.match(regex);
};

const toCamelCase = (inputArray) => {
  let result = '';

  for (let i = 0, len = inputArray.length; i < len; i++) {
    const currentStr = inputArray[i];

    let tempStr = currentStr.toLowerCase();

    if (i !== 0) {
      tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
    }

    result += tempStr;
  }

  return result;
};

const toCamelCaseString = (input) => {
  const words = toWords(input);

  return toCamelCase(words);
};

const FormService = {
  getAllForms,
  addForm,
  updateForm,
  deleteForm,
};

export default FormService;
