import React from 'react';
import { connect } from 'react-redux';

import { Container } from '@material-ui/core';
import TeachingArtists from './teachingArtists/teachingArtists';
import Board from './board/board';

const PeopleComponent = () => (
  <>
    <Container>
      <TeachingArtists />
      <Board />
    </Container>
  </>
);

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const People = connect(mapStateToProps, mapDispatchToProps)(PeopleComponent);

export default People;
