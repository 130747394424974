import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';

import { IconButton, Button, Typography, Box } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import FilterListIcon from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';

import ImportExportIcon from '@material-ui/icons/ImportExport';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DeleteIcon from '@material-ui/icons/Delete';

import { AgGridReact } from 'ag-grid-react';
import FilterEditor from './filterEditor';
import GroupEditor from '../groups/groupEditor';
import GroupCreator from '../groups/groupCreator';
import { saveGroup as saveGroupAction } from '../../redux/actions/groupActions';
import {
  getAllRegistrations,
  addRegistrationFilter as addRegFilter,
  deleteRegistrationFilter as deleteRegFilter,
  saveRegistration as saveRegistrationAction,
} from '../../redux/actions/registrationActions';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

const useStyles = makeStyles((theme) => ({
  table: {},
  tableCell: {
    minWidth: 200,
  },
  tableContainer: {
    width: '90%',
    overflowX: 'auto',
  },
  filters: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  areYouSure: {
    display: 'flex',
  },
  controls: {
    marginLeft: '20px',
  },
  availabilityItem: {
    padding: '5px',
    margin: '0',
    lineHeight: 'normal',
  },
}));

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ViewRegistrationsComponent = ({
  registrations,
  forms,
  personnel,
  addRegistrationFilter,
  deleteRegistrationFilter,
  filters,
  saveGroup,
  saveRegistration,
}) => {
  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [gridState, setGridState] = useState({
    columns: [],
    rows: [],
  });

  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);

  const colRef = {};

  function selectionChanged() {
    const selectedRows = gridApi.getSelectedRows();

    setSelected(selectedRows);
  }

  function saveNewFilter(filter) {
    addRegistrationFilter(filter);
    setFiltersOpen(false);
  }

  function removeFilter(filter) {
    deleteRegistrationFilter(filter);
  }

  function archiveRegistration(registration) {
    registration.archived = true;

    saveRegistration(registration);
  }

  function addToGroup(registration) {
    if (!selected.find((groupItem) => registration === groupItem)) {
      setSelected([...selected, registration]);
    } else {
      setSelected(selected.filter((groupItem) => groupItem !== registration));
    }
  }

  function saveNewGroup(newGroup) {
    saveGroup(newGroup, selected);

    setGroupOpen(false);
  }

  function exportAsCSV() {
    const params = {
      processCellCallback: (cellParams) => {
        if (cellParams.column.colDef.headerName === 'Registration Date') {
          if (cellParams.value) {
            const rDate = new Date(cellParams.value.seconds * 1000);

            return rDate.toLocaleString();
          }
          return '';
        }

        return cellParams.value;
      },
    };

    gridApi.exportDataAsCsv(params);
  }

  function setSpacing() {
    if (gridApi && columnApi) {
      gridApi.resetRowHeights();

      const allColumnIds = [];
      columnApi.getAllColumns().forEach((column) => {
        if (column.name === 'Availability') {
          allColumnIds.push(column.colId);
        }
      });
      columnApi.autoSizeColumns(allColumnIds);
    }
  }

  function dateFormatter(params) {
    if (params.data.registrationDate) {
      const rDate = new Date(params.data.registrationDate.seconds * 1000);
      return rDate.toLocaleString();
    }
    return '';
  }

  function findColumns() {
    const colNames = ['course'];
    const cols = [];

    cols.push({
      field: '',
      headerName: '',
      checkboxSelection: true,
      width: 50,
    });
    cols.push({
      field: 'termName',
      headerName: 'Term',
      sortable: true,
      resizable: true,
      filter: true,
    });
    cols.push({
      field: 'courseName',
      headerName: 'Course',
      sortable: true,
      resizable: true,
      filter: true,
    });
    cols.push({
      field: 'groupName',
      headerName: 'Group',
      sortable: true,
      resizable: true,
      filter: true,
    });

    forms.forEach((form) => {
      form.fields.forEach((field) => {
        if (!colNames.find((item) => item === field.name)) {
          colNames.push(field.name);

          let colDef = {
            field: field.name,
            headerName: field.placeholder,
            sortable: true,
            filter: true,
            hide: true,
            resizable: true,
          };

          if (field.placeholder === 'Availability') {
            colDef = {
              ...colDef,
              autoHeight: true,
              sortable: false,
              filter: false,
              resizable: true,
              cellRendererFramework: (params) => (
                <AvailabilityDisplay params={params} />
              ),
            };
          }

          cols.push(colDef);

          colRef[colDef.field] = colDef;
        }
      });
    });
    /*
    cols.push({
      field: "groupId",
      headerName: "Group ID",
      sortable: true,
      resizable: true,
      filter: true,
    });
*/
    cols.push({
      field: 'invoiceId',
      headerName: 'Invoice ID',
      sortable: true,
      resizable: true,
      filter: true,
    });

    cols.push({
      field: 'invoiceStatus',
      headerName: 'Invoice Status',
      sortable: true,
      resizable: true,
      filter: true,
    });

    cols.push({
      field: 'registrationDate',
      headerName: 'Registration Date',
      valueFormatter: dateFormatter,
      sortable: true,
      resizable: true,
      filter: true,
    });

    cols.push({
      width: 1000,
      cellStyle: { 'justify-content': 'flex-start' },
      cellRendererFramework: (params) => (
        <RegistrationButtons
          params={params}
          archiveRegistration={(reg) => archiveRegistration(reg)}
        />
      ),
    });

    return cols;
  }

  function filterRows() {
    let reg = [...registrations];

    filters.forEach((filter) => {
      reg = reg.filter((r) => {
        if (filter.comparison) {
          return filter.comparison(r);
        }

        return r[filter.property] === filter.value;
      });
    });

    return reg;
  }

  function checkColumnVisibility(rows) {
    rows.forEach((row) => {
      // eslint-disable-next-line no-return-assign
      Object.keys(row).forEach((key) =>
        colRef[key] ? (colRef[key].hide = false) : ''
      );
    });
  }

  function setGridData() {
    const cols = findColumns();
    const rows = filterRows();

    checkColumnVisibility(rows);

    setGridState({
      rows,
      columns: cols,
    });

    setSpacing();
  }

  function handleOnGridReady(params) {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    setSpacing();
  }

  function getRowStyle(params) {
    if (params.data.invoiceStatus === 'open') {
      return { background: 'pink' };
    }

    return {};
  }

  useEffect(() => {
    setGridData();
    setSpacing();
  }, [registrations, forms, personnel, filters]);

  // setSpacing();

  return (
    <main>
      <Box className={classes.controls}>
        <h2>View Registrations</h2>
        <ButtonGroup>
          {selected.length === 0 ? (
            <Button color="primary" disabled>
              <GroupIcon />
            </Button>
          ) : (
            <Tooltip title="Create a new group with the selected registrations.">
              <Button color="primary" onClick={() => setGroupOpen(true)}>
                <GroupIcon />
              </Button>
            </Tooltip>
          )}
          {selected.length === 0 ? (
            <Button color="primary" disabled>
              <GroupAddIcon />
            </Button>
          ) : (
            <Tooltip title="Add selected registrations to an existing group.">
              <Button color="primary" onClick={() => setEditGroupOpen(true)}>
                <GroupAddIcon />
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Add a filter.">
            <Button color="primary" onClick={() => setFiltersOpen(true)}>
              <FilterListIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Export as csv.">
            <Button color="primary" onClick={() => exportAsCSV()}>
              <ImportExportIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>

        {filters && filters.length > 0 ? (
          <div className={classes.filters}>
            {filters.map((filter) => (
              <Chip
                label={`${filter.name}:${filter.value}`}
                key={filter.property}
                onDelete={() => removeFilter(filter)}
                color="secondary"
              />
            ))}
          </div>
        ) : (
          ''
        )}
      </Box>
      <div
        className="ag-theme-material"
        style={{
          height: '60vh',
          width: '100%',
        }}
      >
        <AgGridReact
          columnDefs={gridState.columns}
          toolPanel="columns"
          rowData={gridState.rows}
          rowSelection="multiple"
          onGridReady={(params) => {
            handleOnGridReady(params);
          }}
          onSelectionChanged={() => selectionChanged()}
          getRowStyle={(params) => getRowStyle(params)}
          onFilterChanged={() => setRowCount(gridApi.getDisplayedRowCount())}
        />
        <Box className={classes.controls}>
          <Typography>{gridState.rows.length} Total</Typography>
        </Box>
      </div>
      <Dialog
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <FilterEditor
          columns={gridState.columns}
          onClose={() => setFiltersOpen(false)}
          onSave={saveNewFilter}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={groupOpen}
        onClose={() => setGroupOpen(false)}
        TransitionComponent={Transition}
      >
        <GroupCreator
          onClose={() => setGroupOpen(false)}
          onSave={saveNewGroup}
          personnel={personnel}
          students={selected}
          onStudentsChanged={addToGroup}
          columns={gridState.columns}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={editGroupOpen}
        onClose={() => setEditGroupOpen(false)}
        TransitionComponent={Transition}
      >
        <GroupEditor
          onClose={() => setEditGroupOpen(false)}
          studentsToAdd={selected}
        />
      </Dialog>
    </main>
  );
};

ViewRegistrationsComponent.propTypes = {
  registrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  personnel: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveGroup: PropTypes.func.isRequired,
  addRegistrationFilter: PropTypes.func.isRequired,
  deleteRegistrationFilter: PropTypes.func.isRequired,
  saveRegistration: PropTypes.func.isRequired,
};

const RegistrationButtons = ({ params, archiveRegistration }) => {
  const classes = useStyles();

  const [deleteMode, setDeleteMode] = useState(false);

  const onArchiveClick = () => {
    archiveRegistration(params.data);
    setDeleteMode(false);
  };

  return (
    <>
      {deleteMode ? (
        <div className={classes.areYouSure}>
          <Typography color="secondary" style={{ marginTop: '10px' }}>
            Are you sure you want to archive this registration?
          </Typography>
          <Tooltip title="Confirm Archive">
            <IconButton onClick={() => onArchiveClick()}>
              <CheckIcon color="secondary" />
            </IconButton>
          </Tooltip>{' '}
          <Tooltip title="Don't Archive">
            <IconButton onClick={() => setDeleteMode(false)}>
              <CancelIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <>
          <Tooltip title="Archive Registration">
            <IconButton onClick={() => setDeleteMode(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};

RegistrationButtons.propTypes = {
  params: PropTypes.shape({
    data: PropTypes.shape({}),
  }).isRequired,
  archiveRegistration: PropTypes.func.isRequired,
};

const AvailabilityDisplay = ({ params }) => {
  const classes = useStyles();

  const { availability } = params.data;

  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  function getDayString(day) {
    const arr = [];

    if (day.morning) arr.push('Morning');
    if (day.afternoon) arr.push('Afternoon');
    if (day.evening) arr.push('Evening');

    return arr.join(', ');
  }

  function titleCase(string) {
    const sentence = string.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i += 1) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1, 3);
    }

    return sentence;
  }

  return days
    .filter((day) => availability && getDayString(availability[day]).length > 0)
    .map((day) => (
      <div key={day} className={classes.availabilityItem}>{`${titleCase(
        day
      )}: ${availability ? getDayString(availability[day]) : ''}`}</div>
    ));
};

AvailabilityDisplay.propTypes = {
  params: PropTypes.shape({
    data: PropTypes.shape({
      availability: PropTypes.shape({}),
    }),
  }).isRequired,
};

const mapStateToProps = ({
  registrations: { registrations },
  registrations: { filters },
  forms: { forms },
  courses: { courses },
  personnel: { personnel },
  invoices: { invoices },
  groups: { groups },
  terms: { terms },
}) => {
  let regList = [];

  if (courses && registrations) {
    regList = registrations.filter((r) => !r.archived);

    regList.forEach((r) => {
      const foundCourse = courses.find((c) => c.id === r.courseId);
      const foundGroup = groups.find((g) => g.id === r.groupId);

      r.courseName = foundCourse ? foundCourse.name : '';
      r.groupName = foundGroup ? foundGroup.name : '';

      if (foundCourse) {
        const foundTerm = terms.find((t) => t.id === foundCourse.term.id);
        /*
        if (r.courseName === 'Golden Oak School Choir') {
          console.log(
            'foundCourse:',
            r.courseId,
            foundCourse,
            foundTerm,
            r.groupId,
            foundGroup ? foundGroup.id : ''
          );
        }
*/
        r.termName = foundTerm ? foundTerm.name : '';
      }

      const invoice = invoices.find((i) => i.items[0].registrationId === r.id);

      r.invoiceId = invoice ? invoice.stripeInvoiceId : '';
      r.invoiceStatus = invoice ? invoice.stripeInvoiceStatus : '';
    });
  } else {
    regList = [];
  }

  return {
    registrations: regList,
    forms,
    personnel,
    filters: filters || [],
  };
};

const mapDispatchToProps = {
  getAllRegistrations,
  saveGroup: saveGroupAction,
  addRegistrationFilter: addRegFilter,
  deleteRegistrationFilter: deleteRegFilter,
  saveRegistration: saveRegistrationAction,
};

// eslint-disable-next-line max-len
const ViewRegistrations = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewRegistrationsComponent)
);

export default ViewRegistrations;
