import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import {
  IconButton,
  Button,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EmailIcon from '@material-ui/icons/Email';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { saveGroup as saveGroupAction } from '../../redux/actions/groupActions';

const borderRadius = '10px';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  groupForm: {
    paddingRight: 30,
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
  post: {
    backgroundColor: '#f2f2f2',

    display: 'flex',
    flexDirection: 'column',
    borderRadius: `${borderRadius} ${borderRadius} ${borderRadius} ${borderRadius}`,
    padding: '20px',
  },
}));

const GroupEditorComponent = ({
  onClose,
  saveGroup,
  personnel,
  group,
  groups,
  registrations,
  studentsToAdd,
  columns,
  forms,
}) => {
  const classes = useStyles();

  const [allInSameGroup, setAllInSameGroup] = useState(true);
  const [mode, setMode] = useState('edit');
  const [workingGroupId, setWorkingGroupId] = useState();
  const [groupName, setGroupName] = useState();
  const [groupTeacher, setGroupTeacher] = useState('');
  const [groupStudents, setGroupStudents] = useState([]);
  const [removedStudents, setRemovedStudents] = useState([]);
  const [active, setActive] = useState(true);

  const [selected, setSelected] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [gridState, setGridState] = useState({
    columns: [],
    rows: [],
  });

  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);

  const colRef = {};

  function selectionChanged() {
    const selectedRows = gridApi.getSelectedRows();

    setSelected(selectedRows);
  }

  function dateFormatter(params) {
    if (params.data.registrationDate) {
      const rDate = new Date(params.data.registrationDate.seconds * 1000);
      return rDate.toLocaleString();
    }
    return '';
  }

  function setSpacing() {
    if (gridApi && columnApi) {
      gridApi.resetRowHeights();

      const allColumnIds = [];
      columnApi.getAllColumns().forEach((column) => {
        if (column.name === 'Availability') {
          allColumnIds.push(column.colId);
        }
      });
      columnApi.autoSizeColumns(allColumnIds);
    }
  }

  function findColumns() {
    const colNames = ['course'];
    const cols = [];

    cols.push({
      field: 'termName',
      headerName: 'Term',
      sortable: true,
      resizable: true,
      filter: true,
    });
    cols.push({
      field: 'courseName',
      headerName: 'Course',
      sortable: true,
      resizable: true,
      filter: true,
    });
    cols.push({
      field: 'groupName',
      headerName: 'Group',
      sortable: true,
      resizable: true,
      filter: true,
    });

    forms.forEach((form) => {
      form.fields.forEach((field) => {
        if (!colNames.find((item) => item === field.name)) {
          colNames.push(field.name);

          let colDef = {
            field: field.name,
            headerName: field.placeholder,
            sortable: true,
            filter: true,
            hide: true,
            resizable: true,
          };

          if (field.placeholder === 'Availability') {
            colDef = {
              ...colDef,
              autoHeight: true,
              sortable: false,
              filter: false,
              resizable: true,
              cellRendererFramework: (params) => (
                <AvailabilityDisplay params={params} />
              ),
            };
          }

          cols.push(colDef);

          colRef[colDef.field] = colDef;
        }
      });
    });

    cols.push({
      field: 'invoiceId',
      headerName: 'Invoice ID',
      sortable: true,
      resizable: true,
      filter: true,
    });

    cols.push({
      field: 'invoiceStatus',
      headerName: 'Invoice Status',
      sortable: true,
      resizable: true,
      filter: true,
    });

    cols.push({
      field: 'registrationDate',
      headerName: 'Registration Date',
      valueFormatter: dateFormatter,
      sortable: true,
      resizable: true,
      filter: true,
    });

    return cols;
  }

  function checkColumnVisibility(rows) {
    rows.forEach((row) => {
      // eslint-disable-next-line no-return-assign
      Object.keys(row).forEach((key) =>
        colRef[key] ? (colRef[key].hide = false) : ''
      );
    });
  }

  function setGridData() {
    if (!group) return;

    const cols = findColumns();
    const rows = registrations
      .filter((r) => r.groupId === group.id)
      .sort((a, b) => a.studentFirstName.localeCompare(b.studentFirstName));

    checkColumnVisibility(rows);

    setGridState({
      rows,
      columns: cols,
    });

    setSpacing();
  }

  function handleOnGridReady(params) {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    setSpacing();
  }

  function getRowStyle(params) {
    if (params.data.invoiceStatus === 'open') {
      return { background: 'pink' };
    }

    return {};
  }

  function removeStudentFromGroup(student) {
    setGroupStudents(groupStudents.filter((s) => s.id !== student.id));
    setRemovedStudents([...removedStudents, student]);
  }

  function exportAsCSV() {
    const params = {
      processCellCallback: (cellParams) => {
        if (cellParams.column.colDef.headerName === 'Registration Date') {
          if (cellParams.value) {
            const rDate = new Date(cellParams.value.seconds * 1000);

            return rDate.toLocaleString();
          }
          return '';
        }

        return cellParams.value;
      },
    };

    gridApi.exportDataAsCsv(params);
  }

  function handleSaveClick() {
    if (!group) {
      group = groups.find((g) => g.id === workingGroupId);
    }

    const g = {
      id: workingGroupId,
      teacherId: groupTeacher,
      name: groupName,
      active,
      posts: group.posts || [],
      code: group.code,
    };

    saveGroup(g, groupStudents, removedStudents);

    onClose();
  }

  function emailGroup() {
    const emails = groupStudents.map((st) => st.email);

    const distinctEmails = [...new Set(emails)];

    window.location.href = `mailto:?bcc=${distinctEmails.join(
      ', '
    )}&subject=Cultivating Creative Minds: ${group.name} News`;
  }

  function checkAllInGroupForSameCourse() {
    const courses = groupStudents.map((g) => g.course);

    const distinctCourses = [...new Set(courses)];

    setAllInSameGroup(distinctCourses.length < 2);
  }

  function setGroup(gid) {
    setWorkingGroupId(gid);

    const found = groups.find((g) => g.id === gid);

    setGroupName(found.name);
    setGroupTeacher(found.teacherId);
    setGroupStudents(
      registrations.filter((r) => r.groupId === gid).concat(studentsToAdd)
    );

    checkAllInGroupForSameCourse();
  }

  useEffect(() => {
    if (group) {
      setMode('edit');

      setWorkingGroupId(group.id);
      setGroupName(group.name);
      setGroupTeacher(group.teacherId || personnel[0]);
      setActive(group.active);

      setGroupStudents(registrations.filter((r) => r.groupId === group.id));
    } else {
      setMode('add');

      if (groups.length > 0) {
        setGroup(groups.filter((g) => g.active)[0].id);
      }
    }

    setGridData();
    setSpacing();
  }, [group, registrations, personnel, groups]);

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Tooltip title="Close without saving">
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" className={classes.title}>
            Edit Group
          </Typography>
          <Tooltip title="Save and close">
            <Button
              autoFocus
              color="inherit"
              onClick={handleSaveClick}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <div className={classes.groupForm}>
        <ButtonGroup>
          <Tooltip title="Email the class.">
            <Button color="primary" onClick={() => emailGroup()}>
              <EmailIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Export as csv.">
            <Button color="primary" onClick={() => exportAsCSV()}>
              <ImportExportIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
              name="active"
              color="primary"
            />
          }
          label="Active"
        />
      </div>
      <div className={classes.groupForm}>
        {mode === 'edit' ? (
          <TextField
            id="groupName"
            label="Group Name"
            variant="outlined"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
          />
        ) : (
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-age-native-simple">
              {' '}
              Group{' '}
            </InputLabel>
            <Select
              native
              value={workingGroupId}
              onChange={(e) => setGroup(e.target.value)}
              label="group"
            >
              {groups
                .filter((g) => g.active)
                .map((g) => (
                  <option value={g.id} key={g.id}>
                    {' '}
                    {g.name}{' '}
                  </option>
                ))}
            </Select>
          </FormControl>
        )}

        <Typography>
          {group ? (
            <>
              <strong>Class Code:</strong>
              <a href={`/group/${group.code}`} target="_blank" rel="noreferrer">
                {group.code}
              </a>
            </>
          ) : (
            ''
          )}
        </Typography>

        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-age-native-simple">
            {' '}
            Teaching Artist{' '}
          </InputLabel>
          <Select
            native
            value={groupTeacher}
            onChange={(e) => setGroupTeacher(e.target.value)}
            label="teaching artist"
          >
            {personnel.map((p) => (
              <option value={p.id} key={p.id}>
                {' '}
                {p.firstName} {p.lastName}{' '}
              </option>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h5" className={classes.title}>
          Students
        </Typography>
        <List>
          {groupStudents
            .sort((a, b) =>
              a.studentFirstName.localeCompare(b.studentFirstName)
            )
            .map((r) => (
              <GroupListItem
                key={r.id}
                registration={r}
                columns={columns}
                removeStudentFromGroup={() => removeStudentFromGroup(r)}
              />
            ))}
        </List>
        <div
          className="ag-theme-material"
          style={{
            height: '60vh',
            width: '100%',
            display: 'none',
          }}
        >
          <AgGridReact
            columnDefs={gridState.columns}
            toolPanel="columns"
            rowData={gridState.rows}
            rowSelection="multiple"
            onGridReady={(params) => {
              handleOnGridReady(params);
            }}
            onSelectionChanged={() => selectionChanged()}
            getRowStyle={(params) => getRowStyle(params)}
          />
        </div>
        {allInSameGroup ? (
          <div />
        ) : (
          <Alert severity="error">
            Not all of these registrations signed up for the same course.
          </Alert>
        )}

        {group && group.posts && group.posts.length > 0 ? (
          <Typography variant="h5" className={classes.title}>
            Posts
          </Typography>
        ) : (
          ''
        )}

        {group && group.posts
          ? group.posts.map((p, index) => <PostListItem key={index} post={p} />)
          : ''}
      </div>
    </>
  );
};

GroupEditorComponent.propTypes = {
  personnel: PropTypes.arrayOf(PropTypes.object).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    teacherId: PropTypes.string,
    active: PropTypes.bool,
    posts: PropTypes.arrayOf(PropTypes.shape({})),
    code: PropTypes.string,
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  registrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  studentsToAdd: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  saveGroup: PropTypes.func.isRequired,
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
};

GroupEditorComponent.defaultProps = {
  studentsToAdd: [],
  columns: [],
};

const GroupListItem = ({ registration, removeStudentFromGroup }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  function sentenceCase(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  return (
    <>
      <ListItem>
        <Tooltip title="View registration information">
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Tooltip>
        <ListItemText
          primary={`${registration.studentFirstName} ${registration.studentLastName}`}
          secondary={registration.course || registration.courseName}
        />
        <ListItemSecondaryAction>
          <Tooltip title="Remove from group">
            <IconButton
              variant="outlined"
              onClick={() => removeStudentFromGroup(registration)}
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.nested}>
          {Object.keys(registration).map((col) => (
            <div key={col}>
              {col === 'availability' ? (
                <Typography className={classes.title}>
                  <strong>{`${sentenceCase(col)} :`}</strong>
                  <AvailabilityDisplay availability={registration[col]} />
                </Typography>
              ) : (
                <Typography className={classes.title}>
                  <strong>{`${sentenceCase(col)}`}</strong>
                  {`: ${registration[col]}`}
                </Typography>
              )}
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};

GroupListItem.propTypes = {
  registration: PropTypes.shape({
    studentFirstName: PropTypes.string,
    studentLastName: PropTypes.string,
    course: PropTypes.string,
    courseName: PropTypes.string,
  }).isRequired,
  removeStudentFromGroup: PropTypes.func.isRequired,
};

const PostListItem = ({ post }) => {
  const classes = useStyles();

  function getDateString(date) {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(date);
  }

  function viewAttachment(attachment) {
    window.open(attachment.path);
  }

  return (
    <Paper className={classes.post}>
      <div>
        <i>
          By&nbsp;
          {post.postAuthor}
        </i>
      </div>
      <div>
        <i>{getDateString(post.postDate)}</i>
      </div>
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
      {post.attachments && post.attachments.length > 0
        ? post.attachments.map((attachment) => (
            <Button
              color="primary"
              onClick={() => viewAttachment(attachment)}
              startIcon={<AttachmentIcon />}
            >
              {attachment.name}
            </Button>
          ))
        : ''}
    </Paper>
  );
};

PostListItem.propTypes = {
  post: PropTypes.shape({
    content: PropTypes.string,
    postAuthor: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
    postDate: PropTypes.number,
  }).isRequired,
};

const AvailabilityDisplay = ({ availability }) => {
  const classes = useStyles();

  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  function getDayString(day) {
    const arr = [];

    if (day.morning) arr.push('Morning');
    if (day.afternoon) arr.push('Afternoon');
    if (day.evening) arr.push('Evening');

    return arr.join(', ');
  }

  function titleCase(string) {
    const sentence = string.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i += 1) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1, 3);
    }

    return sentence;
  }

  return days
    .filter((day) => getDayString(availability[day]).length > 0)
    .map((day) => (
      <div key={day} className={classes.availabilityItem}>{`${titleCase(
        day
      )}: ${getDayString(availability[day])}`}</div>
    ));
};

AvailabilityDisplay.propTypes = {
  params: PropTypes.shape({
    data: PropTypes.shape({
      availability: PropTypes.shape({}),
    }),
  }).isRequired,
};

// eslint-disable-next-line max-len
const mapStateToProps = (
  {
    personnel: { personnel },
    registrations: { registrations },
    groups: { groups },
    forms: { forms },
  },
  { group, studentsToAdd }
) => {
  let regList = [];

  if (registrations) {
    regList = registrations.filter((reg) => !reg.archived);
  }

  return {
    groups,
    personnel,
    group,
    registrations: regList,
    studentsToAdd,
    forms,
  };
};

const mapDispatchToProps = {
  saveGroup: saveGroupAction,
};

const GroupEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupEditorComponent);

export default GroupEditor;
