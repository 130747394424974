import { SNACKBAR_SUCCESS, SNACKBAR_CLEAR } from '../actions/snackbarActions';

import initialState from './initialState';

const ui = (state = initialState.ui, action) => {
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarSeverity: action.severity,
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
};

export default ui;
