import { GET_ALL_PROGRAMS_SUCCESS } from '../actions/programActions';

import initialState from './initialState';

const programs = (state = initialState.programs, action) => {
  switch (action.type) {
    case GET_ALL_PROGRAMS_SUCCESS:
      return {
        ...state,
        programs: action.programs,
      };
    default:
      return state;
  }
};

export default programs;
