import VenueService from '../../services/venueService';

/*
 * Action Types
 */
export const ADD_VENUE = 'ADD_VENUE';
export const ADD_VENUE_SUCCESS = 'ADD_VENUE_SUCCESS';
export const UPDATE_VENUE_SUCCESS = 'UPDATE_VENUE_SUCCESS';

export const EDIT_VENUE = 'EDIT_VENUE';
export const DELETE_VENUE = 'DELETE_VENUE';
export const GET_VENUE_BY_ID = 'GET_VENUE_BY_ID';
export const GET_VENUE_BY_SLUG = 'GET_VENUE_BY_SLUG';

export const GET_ALL_VENUES = 'GET_ALL_VENUES';
export const GET_ALL_VENUES_SUCCESS = 'GET_ALL_VENUES_SUCCESS';

export const GET_ALL_VENUE_GROUPS = 'GET_ALL_VENUE_GROUPS';
export const GET_ALL_VENUE_GROUPS_SUCCESS = 'GET_ALL_VENUE_GROUPS_SUCCESS';

/*
 * Action creators
 */

export const editVenue = (venue) => ({ type: EDIT_VENUE, venue });
export const deleteVenue = (venue) => ({ type: DELETE_VENUE, venue });

export const getVenueById = (id) => ({ type: GET_VENUE_BY_ID, id });
export const getVenueBySlug = (slug) => ({ type: GET_VENUE_BY_SLUG, slug });

export const addVenueSuccess = (venue) => ({ type: ADD_VENUE_SUCCESS, venue });
export const updateVenueSuccess = (venue) => ({ type: ADD_VENUE_SUCCESS, venue });
export const saveVenue = (venue) => (dispatch) => {
  if (!venue.id) {
    return VenueService.addVenue(venue).then((newPerson) => {
      dispatch(addVenueSuccess(newPerson));
    }).catch((error) => {
      throw error;
    });
  }
  return VenueService.updateVenue(venue).then(() => {
    dispatch(updateVenueSuccess(venue));
  }).catch((error) => {
    throw error;
  });
};

export const getAllVenueGroupsSuccess = (venueGroups) => ({ type: GET_ALL_VENUE_GROUPS_SUCCESS, venueGroups });
export const getAllVenueGroups = () => (dispatch) => VenueService.getAllVenueGroups().then((venueGroups) => {
  dispatch(getAllVenueGroupsSuccess(venueGroups));
}).catch((error) => {
  throw error;
});

export const getAllVenuesSuccess = (venues) => ({ type: GET_ALL_VENUES_SUCCESS, venues });
export const getAllVenues = () => (dispatch) => VenueService.getAllVenues().then((venues) => {
  dispatch(getAllVenuesSuccess(venues));
}).catch((error) => {
  throw error;
});
