import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton, Box } from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { deleteGroup } from '../../redux/actions/groupActions';

const useStyles = makeStyles(() => ({
  controls: {
    marginLeft: '20px',
  },
}));

const ViewMyGroupsComponent = ({ groups, history }) => {
  const classes = useStyles();

  const manageGroup = (group) => {
    history.push(`/dashboard/manage-group/${group.id}`);
  };

  return (
    <main>
      <Box className={classes.controls}>
        <h2>View My Groups</h2>
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Teacher</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {groups
              .filter((group) => group.active === true)
              .map((group) => (
                <ListItem
                  key={group.id}
                  group={group}
                  manageGroup={() => manageGroup(group)}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
};

ViewMyGroupsComponent.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const ListItem = ({ group, manageGroup }) => (
  <TableRow>
    <TableCell>{group.name}</TableCell>
    <TableCell>{group.teacher}</TableCell>
    <TableCell align="right">
      <IconButton onClick={() => manageGroup(group)}>
        <SupervisorAccountIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

ListItem.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
    teacher: PropTypes.string,
  }).isRequired,
  manageGroup: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  groups: { groups },
  personnel: { personnel },
  auth: { currentUser },
}) => {
  if (groups && personnel) {
    groups.forEach((group) => {
      const foundPersonnel = personnel.find((p) => p.id === group.teacherId);

      // eslint-disable-next-line no-param-reassign
      group.teacher = foundPersonnel
        ? `${foundPersonnel.firstName} ${foundPersonnel.lastName}`
        : '';
    });
  }

  return {
    groups: currentUser
      ? groups.filter((g) => g.teacherId === currentUser.id)
      : [],
  };
};

const mapDispatchToProps = {
  deleteGroup,
};

// eslint-disable-next-line max-len
const ViewMyGroups = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewMyGroupsComponent)
);

export default ViewMyGroups;
