import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { saveProgress } from '../redux/actions/registrationActions';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
  },
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
  bioMain: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  photo: {
    height: 'auto',
    margin: '25px 25px 0 0',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '25vw',
    },
  },
  bio: {
    marginTop: '25px',
  },
}));

const PersonComponent = ({ person }) => {
  const classes = useStyles();

  const email = () => {
    window.location.href = `mailto:${person.email}`;
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.bioMain}>
          <div>
            <img
              src={person.image}
              alt={`${person.firstName} ${person.lastName}`}
              className={classes.photo}
            />
          </div>
          <div>
            <Typography className={classes.headline}>
              {person.firstName} {person.lastName}
            </Typography>
            <Typography>
              <strong>{person.title}</strong>
            </Typography>
            {person.phone ? <Typography>{person.phone}</Typography> : ''}
            {person.email ? (
              <Button
                color="primary"
                onClick={() => email()}
                startIcon={<EmailIcon />}
              >
                Email {person.firstName}
              </Button>
            ) : (
              ''
            )}
            <Typography
              className={classes.bio}
              dangerouslySetInnerHTML={{ __html: person.bio }}
            ></Typography>
          </div>
        </div>
      </div>
    </>
  );
};

PersonComponent.propTypes = {
  person: PropTypes.shape({
    email: PropTypes.string,
    image: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    phone: PropTypes.string,
    bio: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ personnel: { personnel } }, ownProps) => {
  const personId = ownProps.match.params.id;

  const person = personnel.find((p) => p.id === personId) || {};

  return {
    person,
  };
};

const mapDispatchToProps = {
  saveProgress,
};

const Person = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PersonComponent)
);

export default Person;
