import AuthService from '../../services/authService';

/*
 * Action Types
 */
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const signInSuccess = (user) => ({ type: SIGN_IN_SUCCESS, user });
export const signInError = () => ({ type: SIGN_IN_ERROR });

export const signIn = (username, password) => (dispatch) => AuthService.signIn(username, password).then((user) => {
  if (user) {
    dispatch(signInSuccess(user));
  } else {
    dispatch(signInError());

    throw new Error('Invalid username and/or password.');
  }
}).catch((error) => {
  throw error;
});

export const signOutSuccess = () => ({ type: SIGN_OUT_SUCCESS });
export const signOut = () => (dispatch) => AuthService.signOut().then((success) => {
  dispatch(signOutSuccess());
}).catch((error) => {
  throw error;
});

export const resumeSession = () => (dispatch) => AuthService.resumeSession().then((user) => {
  if (user) {
    dispatch(signInSuccess(user));
  }
}).catch((error) => {
  throw error;
});
