import {
  GET_ALL_INVOICES_SUCCESS, ADD_INVOICE_SUCCESS, UPDATE_INVOICE_SUCCESS, DELETE_INVOICE_SUCCESS, ADD_INVOICES_SUCCESS,
} from '../actions/invoiceActions';

import initialState from './initialState';

const invoices = (state = initialState.invoices, action) => {
  switch (action.type) {
    case GET_ALL_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.invoices,
      };
    case ADD_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: [...state.invoices, action.invoice],
      };
    case ADD_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: state.invoices.concat(action.invoices),
      };
    case UPDATE_INVOICE_SUCCESS:
      return {
        invoices: state.invoices.map((invoice) => {
          if (invoice.id !== action.invoice.id) {
            return invoice;
          }

          return action.invoice;
        }),
      };
    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: state.invoices.filter((i) => i.id !== action.invoice.id),
      };
    default:
      return state;
  }
};

export default invoices;
