export default {
  venues: {
    venueGroups: [],
    venues: [],
  },
  programs: {
    //programs: ["Music", "Visual Art", "Dance", "Theater", "Media Arts"],
    programs: [
      'Canyon Springs',
      'Fair Oaks Ranch',
      'Golden Oak',
      'Leona Cox',
      'Mint Canyon',
      'Mitchell',
      'Pinetree',
      'Sulphur Springs',
      'Valley View',
    ],
  },
  courses: {
    courses: [],
  },
  terms: {
    terms: [],
  },
  invoices: {
    invoices: [],
  },
  personnel: {
    personnel: [],
  },
  forms: {
    forms: [],
  },
  registrations: {
    registrations: [],
    filters: [
      {
        property: 'ungrouped',
        name: 'Ungrouped',
        value: '',
        type: 'UNGROUPED',
        comparison: (registration) => {
          if (!registration.groupId) {
            return true;
          }

          return false;
        },
      },
    ],
    registrationProgress: null,
  },
  groups: {
    groups: [],
  },
  ui: {
    successSnackbarOpen: false,
    errorSnackbarOpen: false,
    infoSnackbarOpen: false,
  },
  auth: {
    authenticated: false,
    currentUser: null,
    authenticationError: null,
  },
};
