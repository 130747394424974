import {
  GET_ALL_COURSES_SUCCESS,
  ADD_COURSE_SUCCESS,
  UPDATE_COURSE_SUCCESS,
  DELETE_COURSE_SUCCESS,
} from '../actions/courseActions';

import initialState from './initialState';

const courses = (state = initialState.courses, action) => {
  switch (action.type) {
    case GET_ALL_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.courses,
      };
    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        courses: [...state.courses, action.course],
      };
    case UPDATE_COURSE_SUCCESS:
      return {
        courses: state.courses.map((course) => {
          if (course.id !== action.course.id) {
            return course;
          }

          return action.course;
        }),
      };
    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.filter((c) => c.id !== action.course.id),
      };
    default:
      return state;
  }
};

export default courses;
