/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes, { string } from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button } from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { saveRegistration as saveRegistrationAction } from '../redux/actions/registrationActions';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  subtitle: {
    fontSize: '32px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  statement: {
    fontWeight: 600,
    padding: '30px 0 30px 0',
  },
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
}));

function getSteps() {
  return [
    'Pick a subject',
    'Pick a course',
    'Register',
    'Expectations',
    'Finished',
  ];
}

const RegistrationFormComponent = ({
  registrationProgress,
  courses,
  saveRegistration,
  history,
}) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({});

  const [initials, setInitials] = useState('');

  const [activeStep] = useState(3);
  const steps = getSteps();

  function getCourse() {
    if (!courses || !registrationProgress) return null;

    return courses.find(
      (course) => course.id === registrationProgress.courseId
    );
  }

  useEffect(() => {
    setFormData(registrationProgress);
    console.log('progress:', registrationProgress, getCourse());
  }, [registrationProgress]);

  const submitForm = (event) => {
    event.preventDefault();

    formData.initials = initials;

    saveRegistration(formData).then(() => {
      history.push('/registered');
    });
  };

  return (
    <>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.root}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.container}>
        <Typography className={classes.headline}>Expectations</Typography>

        <Typography>
          "I understand that if my child will be checking out an instrument from
          the school, I ("Parent") must attend the first day of class to
          complete the necessary paperwork for instrument checkout."{' '}
        </Typography>

        <Typography>
          "I understand that registration does not guarantee my child a spot in
          the class. I understand that I will receive an email before the first
          class confirming my child's addition to the class or, alternatively,
          the wait list."{' '}
        </Typography>

        <Typography className={classes.statement}>
          “I agree to help my child remember to attend their scheduled class.”{' '}
        </Typography>
        <Typography>
          The teaching artists will do their best to communicate the schedule,
          but if there is any question, please feel free to contact the teaching
          artist. The class will have a blog accessible via a class code. Most
          pertinent information will be posted on the class blog.{' '}
        </Typography>

        <Typography className={classes.statement}>
          “I understand that my child must maintain good citizenship grades in
          music class. Failure to do so may result in my child being removed
          from the class.”
        </Typography>

        <Typography className={classes.statement}>
          “I understand that my child must maintain good citizenship grades in
          school. Failure to do so may result in my child being removed from the
          class.”
        </Typography>

        <Typography className={classes.statement}>
          “I understand that my child must attend class regularly. I understand
          that there is limited space in each class and poor attendance will
          result in my child being removed from the class in order to make room
          for another student.”
        </Typography>

        <Typography className={classes.statement}>
          “I hereby grant to Cultivating Creative Minds, its sponsors,
          organizers, and/or assigns (“CCM”) the irrevocable and unrestricted
          right to use and publish photographs (including video photography and
          performance) of my child, or in which my child may be included, for
          publication, electronic reproductions (web sites) and/or promotional
          materials or any other purpose and in any manner or medium. In
          addition, I grant my permission to alter the same without restriction;
          and to copyright the same. I hereby release the photographer and CCM
          from all claims and liability relating to said photographs.”
        </Typography>

        {getCourse() && getCourse().price !== '0' ? (
          <>
            <Typography className={classes.statement}>
              “I understand that there are no refunds after the third class.”
            </Typography>

            <Typography>
              If any payment is due, you will receive an invoice from us after
              the first class meeting. Stripe.com will handle all credit card
              processing. Follow the link in your invoice email to pay for your
              class.{' '}
            </Typography>
          </>
        ) : (
          <></>
        )}

        <Typography className={classes.statement}>
          Please initial below to accept all terms of your registration.
        </Typography>

        <form onSubmit={submitForm} className={classes.form}>
          <div className="formElement">
            <TextField
              id="name"
              label="Initials"
              value={initials}
              required
              onChange={(e) => setInitials(e.target.value)}
              variant="outlined"
            />
          </div>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {' '}
            Register{' '}
          </Button>
        </form>
      </div>
    </>
  );
};

RegistrationFormComponent.propTypes = {
  registrationProgress: PropTypes.shape({ courseId: string }).isRequired,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveRegistration: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({
  registrations: { registrationProgress },
  courses: { courses },
}) => ({
  registrationProgress,
  courses,
});

const mapDispatchToProps = {
  saveRegistration: saveRegistrationAction,
};

const RegistrationForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegistrationFormComponent)
);

export default RegistrationForm;
