import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';

import { Typography, Button } from '@material-ui/core';

import { Link } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

const borderRadius = '30px';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
}));

function getSteps() {
  return [
    'Pick a subject',
    'Pick a course',
    'Register',
    'Expectations',
    'Finished',
  ];
}

const ViewProgramCoursesComponent = ({ courses, program, history }) => {
  const classes = useStyles();

  const [activeStep] = useState(1);
  const steps = getSteps();

  return (
    <>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.root}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.container}>
        <Typography className={classes.headline}>Pick A Course</Typography>
        {courses.map((course) => (
          <CourseItem course={course} key={course.id} />
        ))}
      </div>
    </>
  );
};

const useCourseItemStyles = (backgroundImage) =>
  makeStyles((theme) => ({
    title: {
      width: '100%',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '32px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '48px',
      },
    },
    price: {
      width: '100%',
      fontWeight: 500,
      padding: '20px 0 20px 0',
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '24px',
      },
    },
    content: {
      width: '100%',
      fontWeight: 400,
      fontSize: '14px',
    },
    registerButton: {
      borderRadius,
      padding: '20px 30px 20px 30px',
      marginTop: '30px',
    },
    moreInfo: {
      borderRadius: '0 0 0 0',
    },
    programInfo: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: '30px',
    },
    programDetails: {
      backgroundColor: '#f2f2f2',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        borderRadius: '0 0 0 0',
        padding: '10px',
      },
      [theme.breakpoints.up('sm')]: {
        borderRadius: `${borderRadius} 0px 0px ${borderRadius}`,
        padding: '20px',
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: `${borderRadius} 0px 0px ${borderRadius}`,
        padding: '50px',
      },
    },
    heroImage: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        borderRadius: '0 0 0 0',
        padding: '10px',
      },
      [theme.breakpoints.up('sm')]: {
        borderRadius: `0px ${borderRadius} ${borderRadius} 0px`,
        padding: '20px',
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: `0px ${borderRadius} ${borderRadius} 0px`,
        padding: '50px',
      },
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
    },
    linkText: {
      textDecoration: 'none',
    },
  }));

const CourseItem = ({ course }) => {
  const classes = useCourseItemStyles(course.image)();

  const [open, setOpen] = useState(false);

  return (
    <div className={classes.programInfo} key={course.id}>
      <div className={classes.programDetails}>
        <Typography variant="h5" className={classes.title}>
          {course.name}
        </Typography>
        <Typography variant="h5" className={classes.price}>
          Price: ${course.price}
        </Typography>
        <Typography component="span" className={classes.content}>
          <div dangerouslySetInnerHTML={{ __html: course.description }} />
          {course.notes ? (
            <>
              <br />
              <IconButton
                aria-label="expand row"
                variant="contained"
                size="small"
                className={classes.moreInfo}
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                Click For More Info
              </IconButton>
              <br />

              <Collapse in={open} timeout="auto" unmountOnExit>
                <div dangerouslySetInnerHTML={{ __html: course.notes }} />
              </Collapse>
            </>
          ) : (
            ''
          )}
          <Link
            color="inherit"
            to={`/courses/${course.id}/register`}
            className={classes.linkText}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.registerButton}
            >
              Register
            </Button>
          </Link>
        </Typography>
      </div>
      <div className={classes.heroImage} />
    </div>
  );
};

const mapStateToProps = (
  { courses: { courses }, terms: { terms } },
  ownProps
) => {
  const slug = ownProps.match.params ? ownProps.match.params.slug : null;

  courses = courses.filter(
    (course) =>
      course.program.toLowerCase().replace(/\s/g, '') ===
      slug.toLowerCase().replace(/\s/g, '')
  );

  courses = courses.filter((course) => {
    const term = terms.find((term) => term.id === course.term.id);

    return term.active;
  });

  courses = courses.filter((course) => course.active);

  return {
    program:
      courses.length > 0
        ? courses[0].program
        : slug.substr(0, 1).toUpperCase() + slug.substr(1),
    courses,
  };
};

const mapDispatchToProps = {};

const ViewProgramCourses = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewProgramCoursesComponent)
);

export default ViewProgramCourses;
