import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  IconButton,
  Button,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import { deletePerson } from '../../redux/actions/personnelActions';

const useStyles = makeStyles((theme) => ({
  controls: {
    marginLeft: '20px',
  },
}));

const ViewPersonnelComponent = ({ personnel, deletePerson, history }) => {
  const classes = useStyles();

  const [hideInactive, setHideInactive] = useState(true);

  const addPerson = () => {
    history.push('/dashboard/add-person');
  };

  const editPerson = (person) => {
    history.push(`/dashboard/add-person/${person.id}`);
  };

  const handleDelete = (person) => {
    deletePerson(person);
  };

  useEffect(() => {
    console.log('personnel:', personnel);
  });

  return (
    <main>
      <Box className={classes.controls}>
        <h2>View Personnel</h2>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={() => addPerson()}
        >
          Add Person
        </Button>
      </Box>
      <Box className={classes.controls}>
        <Button onClick={() => setHideInactive(!hideInactive)}>
          {hideInactive ? 'Show Inactive' : 'Hide Inactive'}
        </Button>
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Active</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {personnel
              .filter((person) => !hideInactive || person.active === true)
              .map((person) => (
                <ListItem
                  key={person.id}
                  person={person}
                  editPerson={editPerson}
                  deletePerson={handleDelete}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
};

const ListItem = ({ person, editPerson, deletePerson }) => {
  const [deleteMode, setDeleteMode] = useState(false);

  const handleDelete = () => {
    deletePerson(person);
    setDeleteMode(false);
  };

  return (
    <TableRow>
      <TableCell>
        <div>
          {person.firstName} {person.lastName}
        </div>
      </TableCell>
      <TableCell>{person.title}</TableCell>
      <TableCell>
        <div>
          <a href={`mailto:${person.email}`}>{person.email}</a>
        </div>
        <div>{person.phone}</div>
      </TableCell>
      <TableCell>{person.active.toString()}</TableCell>
      <TableCell align="right">
        {deleteMode ? (
          <>
            <Typography color="secondary">
              Are you sure you'd like to delete this person?
            </Typography>
            <Tooltip title="Confirm delete?">
              <IconButton onClick={() => handleDelete()}>
                <CheckIcon color="secondary" />
              </IconButton>
            </Tooltip>{' '}
            &nbsp;
            <Tooltip title="Don't delete">
              <IconButton onClick={() => setDeleteMode(false)}>
                <CancelIcon color="secondary" />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        ) : (
          <>
            <Tooltip title="Edit this person">
              <IconButton onClick={() => editPerson(person)}>
                <EditIcon />
              </IconButton>
            </Tooltip>{' '}
            &nbsp;
            <Tooltip title="Delete this person">
              <IconButton onClick={() => setDeleteMode(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = ({ personnel: { personnel } }, ownProps) => {
  return {
    personnel: personnel.sort((a, b) => (a.active > b.active ? -1 : 1)),
  };
};

const mapDispatchToProps = {
  deletePerson,
};

const ViewPersonnel = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewPersonnelComponent)
);

export default ViewPersonnel;
