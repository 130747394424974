import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  IconButton,
  Button,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import { deleteTerm } from "../../redux/actions/termActions";

const useStyles = makeStyles((theme) => ({
  controls: {
    marginLeft: "20px",
  },
}));

const ViewTermsComponent = ({ terms, deleteTerm, history }) => {
  const classes = useStyles();

  const addTerm = () => {
    history.push("/dashboard/add-term");
  };

  const editTerm = (term) => {
    history.push(`/dashboard/add-term/${term.id}`);
  };

  const handleDelete = (term) => {
    deleteTerm(term);
  };

  return (
    <main>
      <Box className={classes.controls}>
        <h2>View Terms</h2>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={() => addTerm()}
        >
          Add Term
        </Button>
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Active</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {terms.map((term) => (
              <ListItem
                key={term.id}
                term={term}
                editTerm={editTerm}
                deleteTerm={handleDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
};

const ListItem = ({ term, editTerm, deleteTerm }) => {
  const [deleteMode, setDeleteMode] = useState(false);

  const handleDelete = () => {
    deleteTerm(term);
    setDeleteMode(false);
  };

  return (
    <TableRow>
      <TableCell>{term.name}</TableCell>
      <TableCell>{term.active.toString()}</TableCell>
      <TableCell align="right">
        {deleteMode ? (
          <>
            <Typography color="secondary">
              Are you sure you'd like to delete this term?
            </Typography>
            <Tooltip title="Confirm Delete">
              <IconButton onClick={() => handleDelete()}>
                <CheckIcon color="secondary" />
              </IconButton>
            </Tooltip>{" "}
            &nbsp;
            <Tooltip title="Don't delete">
              <IconButton onClick={() => setDeleteMode(false)}>
                <CancelIcon color="secondary" />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        ) : (
          <>
            <Tooltip title="Edit term">
              <IconButton onClick={() => editTerm(term)}>
                <EditIcon />
              </IconButton>
            </Tooltip>{" "}
            &nbsp;
            <Tooltip title="Delete term">
              <IconButton onClick={() => setDeleteMode(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = ({ terms: { terms } }, ownProps) => ({
  terms,
});

const mapDispatchToProps = {
  deleteTerm,
};

const ViewTerms = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewTermsComponent)
);

export default ViewTerms;
