import { db, functions } from '../firebase';

const sendRegistrationConfirmation = functions.httpsCallable(
  'sendRegistrationConfirmation'
);

const getAllRegistrations = () =>
  new Promise((resolve) => {
    db.collection('registrations').onSnapshot((snapshot) => {
      const allRegistrations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      resolve(allRegistrations);
    });
  });

const getRegistrationsByCourse = (course) =>
  new Promise((resolve) => {
    db.collection('registrations')
      .where('courseId', '==', course.id)
      .onSnapshot((snapshot) => {
        const allRegistrations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        resolve(allRegistrations);
      });
  });

const getRegistrationsByGroup = (group) =>
  new Promise((resolve) => {
    db.collection('registrations')
      .where('groupId', '==', group.id)
      .onSnapshot((snapshot) => {
        let allGroups = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        allGroups = allGroups.filter((reg) => !reg.archived);

        resolve(allGroups || []);
      });
  });

const addRegistration = (registration) =>
  new Promise((resolve) => {
    registration.registrationDate = new Date();

    db.collection('registrations')
      .add(registration)
      .then((r) => {
        const reg = {
          ...registration,
          id: r.id,
        };

        sendRegistrationConfirmation(reg);
        resolve(reg);
      });
  });

const updateRegistration = (registration) =>
  new Promise((resolve) => {
    delete registration.invoiceId;
    delete registration.invoiceStatus;

    db.collection('registrations')
      .doc(registration.id)
      .set(registration)
      .then(() => {
        resolve(registration);
      });
  });

const RegistrationService = {
  getAllRegistrations,
  addRegistration,
  updateRegistration,
  getRegistrationsByCourse,
  getRegistrationsByGroup,
};

export default RegistrationService;
