import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { TextField, Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';
import { saveCourse } from '../../redux/actions/courseActions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  imagePreview: {
    height: 'auto',
    width: '25vw',
  },
}));

const AddCourseComponent = ({
  saveCourse, programs, terms, forms, course, history,
}) => {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [term, setTerm] = useState('');
  const [program, setProgram] = useState('');
  const [form, setForm] = useState('');
  const [price, setPrice] = useState('');
  const [teacherRate, setTeacherRate] = useState('');
  const [maxRegistrations, setMaxRegistrations] = useState('');
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [image, setImage] = useState('');
  const [active, setActive] = useState(true);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (course) {
      setName(course.name);
      setActive(course.active);
      setIndex(course.index);

      setTerm(course.term.id);
      setProgram(course.program);
      setForm(course.form.id);

      setPrice(course.price);
      setTeacherRate(course.teacherRate);
      setMaxRegistrations(course.maxRegistrations);
      setDescription(course.description || '');
      setNotes(course.notes || '');

      setImage(course.image || '');
    } else if (!course) {
      if (programs.length > 0) {
        setProgram(programs[0]);
      }

      if (terms.length > 0) {
        setTerm(terms[0].id);
      }

      if (forms.length > 0) {
        setForm(forms[0].id);
      }

      setPrice('150');
      setTeacherRate('40');
      setMaxRegistrations('6');
      setIndex(0);
    }
  }, [programs, terms, forms, course]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const c = {
      id: -1,
      active,
      name,
      index,
      term,
      program,
      form,
      price,
      teacherRate,
      maxRegistrations,
      description,
      notes,
      image,
    };

    if (course) {
      c.id = course.id;
    }

    saveCourse(c).then(() => history.push('/dashboard/view-courses'));
  };

  return (
    <main>
      <h2>Add Course </h2>
      <form onSubmit={handleSubmit} className={classes.root}>
        <div className="formElement">
          <FormControlLabel
            control={(
              <Switch
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                name="active"
                color="primary"
              />
                          )}
            label="Active"
          />
        </div>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel htmlFor="outlined-age-native-simple"> Term </InputLabel>
          <Select
            native
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            label="term"
            inputProps={{
              name: 'term',
              id: 'outlined-age-native-simple',
            }}
          >
            {
                            terms.map((term) => (
                              <option value={term.id} key={term.id}>
                                {' '}
                                {term.name}
                                {' '}
                              </option>
                            ))
}
          </Select>
        </FormControl>

        <FormControl variant="outlined" fullWidth required>
          <InputLabel htmlFor="outlined-age-native-simple"> Program </InputLabel>
          <Select
            native
            value={program}
            onChange={(e) => setProgram(e.target.value)}
            label="program"
            inputProps={{
              name: 'program',
              id: 'outlined-age-native-simple',
            }}
          >
            {
                            programs.map((program) => (
                              <option value={program} key={program}>
                                {' '}
                                {program}
                                {' '}
                              </option>
                            ))
}
          </Select>
        </FormControl>

        <TextField
          id="name"
          label="Name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          fullWidth
        />

        <FormControl fullWidth variant="outlined" required>
          <InputLabel htmlFor="outlined-adornment-price">Price</InputLabel>
          <OutlinedInput
            id="outlined-adornment-price"
            value={price}
            label="Price"
            onChange={(e) => setPrice(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>

        <FormControl fullWidth variant="outlined" required>
          <InputLabel htmlFor="outlined-adornment-teacher-rate">Teacher Rate</InputLabel>
          <OutlinedInput
            id="outlined-adornment-teacher-rate"
            value={teacherRate}
            label="Teacher Rate"
            onChange={(e) => setTeacherRate(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>

        <TextField
          id="maxRegistrations"
          value={maxRegistrations}
          onChange={(e) => setMaxRegistrations(e.target.value)}
          label="Maximum Registrations"
          required
          variant="outlined"
          fullWidth
        />

        <TextField
          id="description"
          label="Description"
          multiline
          rows={10}
          required
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />

        <TextField
          id="notes"
          label="Notes"
          multiline
          rows={10}
          variant="outlined"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
        />

        <FormControl variant="outlined" fullWidth required>
          <InputLabel htmlFor="outlined-age-native-simple"> Registration Form </InputLabel>
          <Select
            native
            value={form}
            onChange={(e) => setForm(e.target.value)}
            label="Registration Form"
            inputProps={{
              name: 'form',
              id: 'outlined-age-native-simple',
            }}
          >
            {
                            forms.map((form) => (
                              <option value={form.id} key={form.id}>
                                {' '}
                                {form.name}
                                {' '}
                              </option>
                            ))
}
          </Select>
        </FormControl>

        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
        />

        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="default"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Photo Upload
          </Button>
        </label>
        <br />

        {image ? (<img src={image} alt="course_photo" className={classes.imagePreview} />) : (<div />)}

        <Button type="submit" variant="contained" color="primary" fullWidth> Submit </Button>
      </form>
    </main>
  );
};

const mapStateToProps = ({
  programs: { programs }, terms: { terms }, forms: { forms }, courses: { courses },
}, ownProps) => {
  const courseId = ownProps.match.params.id;

  const activeTerms = terms.filter((term) => term.active);

  return {
    course: courses.find((course) => course.id === courseId) || null,
    programs,
    terms: activeTerms || [],
    forms,
  };
};

const mapDispatchToProps = {
  saveCourse,
};

const AddCourse = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCourseComponent));

export default AddCourse;
