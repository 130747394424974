import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  IconButton,
  Typography,
  Box,
  Tooltip,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {
  deleteGroup as deleteGroupAction,
  invoiceGroup as invoiceGroupAction,
} from '../../redux/actions/groupActions';

import GroupEditor from './groupEditor';

const useStyles = makeStyles((theme) => ({
  controls: {
    marginLeft: '20px',
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ViewGroupsComponent = ({
  groups,
  personnel,
  forms,
  deleteGroup,
  invoiceGroup,
  history,
}) => {
  const classes = useStyles();

  const [groupOpen, setGroupOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [hideInactive, setHideInactive] = useState(true);

  const editGroup = (group) => {
    setSelectedGroup(group);
    setGroupOpen(true);
  };

  const handleDelete = (group) => {
    deleteGroup(group);
  };

  const handleInvoiceGroup = (group) => {
    invoiceGroup(group);
  };

  return (
    <main>
      <Box className={classes.controls}>
        <h2>View Groups</h2>
      </Box>
      <Box className={classes.controls}>
        <Button onClick={() => setHideInactive(!hideInactive)}>
          {hideInactive ? 'Show Inactive' : 'Hide Inactive'}
        </Button>
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Teacher</TableCell>
              <TableCell>Active</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {groups
              .filter((group) => !hideInactive || group.active === true)
              .map((group) => (
                <ListItem
                  key={group.id}
                  group={group}
                  editGroup={editGroup}
                  deleteGroup={handleDelete}
                  invoiceGroup={handleInvoiceGroup}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullScreen
        open={groupOpen}
        onClose={() => setGroupOpen(false)}
        TransitionComponent={Transition}
      >
        <GroupEditor
          onClose={() => setGroupOpen(false)}
          group={selectedGroup}
        />
      </Dialog>
    </main>
  );
};

const ListItem = ({ group, editGroup, deleteGroup, invoiceGroup }) => {
  const [deleteMode, setDeleteMode] = useState(false);

  const handleDelete = () => {
    deleteGroup(group);
    setDeleteMode(false);
  };

  return (
    <TableRow>
      <TableCell>{group.name}</TableCell>
      <TableCell>{group.teacher}</TableCell>
      <TableCell>{group.active === true ? 'true' : 'false'}</TableCell>
      <TableCell align="right">
        {deleteMode ? (
          <>
            <Typography color="secondary">
              Are you sure you'd like to delete this group?
            </Typography>
            <Tooltip title="Confirm delete">
              <IconButton onClick={() => handleDelete()}>
                <CheckIcon color="secondary" />
              </IconButton>
            </Tooltip>{' '}
            &nbsp;
            <Tooltip title="Don't delete">
              <IconButton onClick={() => setDeleteMode(false)}>
                <CancelIcon color="secondary" />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        ) : (
          <>
            <Tooltip title="Send invoices to group">
              <IconButton onClick={() => invoiceGroup(group)}>
                <ReceiptIcon />
              </IconButton>
            </Tooltip>{' '}
            <Tooltip title="Edit group">
              <IconButton onClick={() => editGroup(group)}>
                <EditIcon />
              </IconButton>
            </Tooltip>{' '}
            &nbsp;
            <Tooltip title="Delete group">
              <IconButton onClick={() => setDeleteMode(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

ListItem.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
    teacher: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  editGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  invoiceGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (
  { groups: { groups }, personnel: { personnel }, forms: { forms } },
  ownProps
) => {
  if (groups && personnel) {
    groups.forEach((g) => {
      const foundPersonnel = personnel.find((p) => p.id === g.teacherId);

      g.teacher = foundPersonnel
        ? `${foundPersonnel.firstName} ${foundPersonnel.lastName}`
        : '';
    });
  } else {
    groups = [];
  }

  return {
    groups,
    personnel,
  };
};

const mapDispatchToProps = {
  deleteGroup: deleteGroupAction,
  invoiceGroup: invoiceGroupAction,
};

const ViewGroups = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewGroupsComponent)
);

export default ViewGroups;
