import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import DynamicForm from '../../forms/dynamicForm';

import { saveRegistration } from '../../redux/actions/registrationActions';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
}));

const EditRegistrationComponent = ({
  registration, course, saveRegistration, history,
}) => {
  const classes = useStyles();

  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState();

  useEffect(() => {
    if (course && course.form && registration) {
      course.form.get().then((form) => {
        setFields(form.data().fields);
        setFormData(registration);
      });
    }
  }, [course, registration]);

  const submitForm = (event) => {
    event.preventDefault();

    saveRegistration(formData).then(() => {
      history.push('/dashboard/view-registrations');
    });
  };

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.headline}>Edit Registration</Typography>
        <form onSubmit={submitForm} className={classes.form}>
          <DynamicForm fields={fields} formData={formData} />
          <Button type="submit" variant="contained" color="primary" fullWidth> Submit </Button>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = ({ registrations: { registrations }, courses: { courses } }, ownProps) => {
  const registrationId = ownProps.match.params.id;

  const registration = registrations.find((r) => r.id === registrationId) || {};

  const course = courses.find((c) => c.id === registration.courseId);

  return {
    registration,
    course,
  };
};

const mapDispatchToProps = {
  saveRegistration,
};

const EditRegistration = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRegistrationComponent));

export default EditRegistration;
