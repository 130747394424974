import base64 from 'react-native-base64';
import { db } from '../firebase';

const signIn = (username, password) => new Promise((resolve, reject) => {
  db.collection('personnel')
    .where('username', '==', username)
    .where('password', '==', base64.encode(password))
    .onSnapshot((snapshot) => {
      const allAccounts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (allAccounts && allAccounts.length > 0 && allAccounts[0].active) {
        resolve(allAccounts[0]);

        localStorage.setItem('un', base64.encode(username));
        localStorage.setItem('token', base64.encode(password));
      }

      resolve(null);
    });
});

const signOut = () => new Promise((resolve, reject) => {
  localStorage.setItem('un', null);
  localStorage.setItem('token', null);

  resolve(true);
});

const resumeSession = () => new Promise((resolve, reject) => {
  if (!localStorage.getItem('un') || !localStorage.getItem('token')) resolve(null);

  const username = base64.decode(localStorage.getItem('un'));
  const password = localStorage.getItem('token');

  db.collection('personnel')
    .where('username', '==', username)
    .where('password', '==', password)
    .onSnapshot((snapshot) => {
      const allAccounts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (allAccounts && allAccounts.length > 0 && allAccounts[0].active) {
        resolve(allAccounts[0]);
      }

      resolve(null);
    });
});

const AuthService = {
  signIn,
  signOut,
  resumeSession,
};

export default AuthService;
