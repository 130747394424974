import {
  GET_ALL_GROUPS_SUCCESS,
  ADD_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
} from '../actions/groupActions';

import initialState from './initialState';

const groups = (state = initialState.groups, action) => {
  switch (action.type) {
    case GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.groups,
      };
    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, action.group],
      };
    case UPDATE_GROUP_SUCCESS:
      return {
        groups: state.groups.map((group) => {
          if (group.id !== action.group.id) {
            return group;
          }

          return action.group;
        }),
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.filter((g) => g.id !== action.group.id),
      };
    default:
      return state;
  }
};

export default groups;
