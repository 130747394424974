import React, { useEffect, useState } from 'react';

import InputTextField from './inputTextField';
import DropDownSelect from './dropDownSelect';
import AvailabilityField from './availabilityField';

export const TEXT_FIELD = 'TEXT_FIELD';
export const DROP_DOWN = 'DROP_DOWN';
export const AVAILABILITY = 'AVAILABILITY';

const DynamicForm = ({ fields, formData }) => {
  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    if (fields && formData) {
      setFormFields(fields);
    }
  }, [fields, formData]);

  const handleChange = (value, field) => {
    formData[field] = value;
  };

  return formFields.map((field) => {
    if (field.type === TEXT_FIELD) {
      return (
        <InputTextField
          name={field.name}
          value={formData[field.name]}
          required={field.required}
          key={field.placeholder}
          placeholder={field.placeholder}
          handleChange={(e) => handleChange(e.target.value, field.name)}
        />
      );
    }

    if (field.type === DROP_DOWN) {
      formData[field.name] = field.values.split(',')[0].replace(/^\s+|\s+$/g, '');

      return (
        <DropDownSelect
          name={field.name}
          required={field.required}
          key={field.placeholder}
          placeholder={field.placeholder}
          value={field.values.split(',')[0].replace(/^\s+|\s+$/g, '')}
          values={field.values.split(',').map((item) => item.replace(/^\s+|\s+$/g, ''))}
          handleChange={(e) => handleChange(e.target.value, field.name)}
        />
      );
    }

    if (field.type === AVAILABILITY) {
      return <AvailabilityField values={formData} key={field.placeholder} />;
    }

    return <div />;
  });
};

export default DynamicForm;
