import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyDJILm0_7eL8HIhsZ4PqgtMPs1sYJcYUuY',
  authDomain: 'cultivating-creative-minds.firebaseapp.com',
  databaseURL: 'https://cultivating-creative-minds.firebaseio.com',
  projectId: 'cultivating-creative-minds',
  storageBucket: 'cultivating-creative-minds.appspot.com',
  messagingSenderId: '224911873854',
  appId: '1:224911873854:web:b518ecc95a63b4ac397e6f',
  measurementId: 'G-PDWEK3Q0T0',
};

firebase.initializeApp(firebaseConfig);

export const storage = firebase.storage();

export const db = firebase.firestore();
export const functions = firebase.functions();
