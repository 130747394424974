import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const DropDownSelect = ({
  value, placeholder, required, values, handleChange,
}) => (
  <>
    {required ? (
      <FormControl required variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-age-native-simple">
          {' '}
          {placeholder}
          {' '}
        </InputLabel>
        <Select
          native
          defaultValue={value}
          onChange={handleChange}
          label={placeholder}
          required={required}
          inputProps={{
            name: placeholder,
            id: 'outlined-age-native-simple',
          }}
        >
          {
                    values.map((val) => (
                      <option value={val} key={val}>
                        {' '}
                        {val}
                        {' '}
                      </option>
                    ))
                }
        </Select>
      </FormControl>
    )
      : (
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-age-native-simple">
            {' '}
            {placeholder}
            {' '}
          </InputLabel>
          <Select
            native
            defaultValue={value}
            onChange={handleChange}
            label={placeholder}
            required={required}
            inputProps={{
              name: placeholder,
              id: 'outlined-age-native-simple',
            }}
          >
            {
                        values.map((val) => (
                          <option value={val} key={val}>
                            {' '}
                            {val}
                            {' '}
                          </option>
                        ))
                    }
          </Select>
        </FormControl>
      )}
  </>
);

export default DropDownSelect;
