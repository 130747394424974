import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import DynamicForm from '../forms/dynamicForm';
import { saveProgress as saveProgressAction } from '../redux/actions/registrationActions';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
}));

function getSteps() {
  return ['Pick a subject', 'Pick a course', 'Register', 'Expectations', 'Finished'];
}

const RegistrationFormComponent = ({ course, saveProgress, history }) => {
  const classes = useStyles();

  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState({});

  const [activeStep] = useState(2);
  const steps = getSteps();

  useEffect(() => {
    if (course && course.form) {
      course.form.get().then((form) => {
        setFields(form.data().fields);
        setFormData({});
      });
    }
  }, [course]);

  const submitForm = (event) => {
    event.preventDefault();

    formData.courseId = course.id;

    saveProgress(formData);

    history.push('/expectations');
  };

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.root}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.container}>
        <Typography className={classes.headline}>
          Register for&nbsp;
          {course.name}
        </Typography>
        <form onSubmit={submitForm} className={classes.form}>
          <DynamicForm fields={fields} formData={formData} />
          <Button type="submit" variant="contained" color="primary" fullWidth> Register </Button>
        </form>
      </div>
    </>
  );
};

RegistrationFormComponent.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
    form: PropTypes.shape({
      get: PropTypes.func,
    }),
    name: PropTypes.string,
  }).isRequired,
  saveProgress: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ courses: { courses } }, ownProps) => {
  const courseId = ownProps.match.params.id;

  const course = courses.find((c) => c.id === courseId) || {};

  return {
    course,
  };
};

const mapDispatchToProps = {
  saveProgress: saveProgressAction,
};

// eslint-disable-next-line max-len
const RegistrationForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationFormComponent));

export default RegistrationForm;
