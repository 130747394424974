import React, { useEffect, useState, forwardRef } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import { IconButton, Button, Typography, Box, Paper } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import EmailIcon from '@material-ui/icons/Email';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Slide from '@material-ui/core/Slide';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';

import AddPost from './addPost';

import { saveGroup as saveGroupAction } from '../../redux/actions/groupActions';

const borderRadius = '10px';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    marginBottom: theme.spacing(2),
  },
  groupForm: {
    paddingRight: 30,
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    padding: '30px 0 30px 0',
  },
  post: {
    backgroundColor: '#f2f2f2',

    display: 'flex',
    flexDirection: 'column',
    borderRadius: `${borderRadius} ${borderRadius} ${borderRadius} ${borderRadius}`,
    padding: '20px',
  },
  controls: {
    marginLeft: '20px',
  },
}));

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const GroupManagerComponent = ({
  personnel,
  group,
  groups,
  registrations,
  saveGroup,
}) => {
  const classes = useStyles();

  const [groupStudents, setGroupStudents] = useState([]);
  const [postOpen, setPostOpen] = useState(false);
  const [posts, setPosts] = useState(null);

  function save() {
    saveGroup(group);
  }

  function setGroup(gid) {
    setGroupStudents(registrations.filter((r) => r.groupId === gid));
  }

  function emailGroup() {
    const emails = groupStudents.map((st) => st.email);

    const distinctEmails = [...new Set(emails)];

    window.location.href = `mailto:?bcc=${distinctEmails.join(
      ', '
    )}&subject=Cultivating Creative Minds: ${group.name} News`;
  }

  function postToGroup() {
    setPostOpen(true);
  }

  function deletePost(post) {
    group.posts = group.posts.filter((p) => p !== post);

    setPosts(group.posts);
    save();
  }

  useEffect(() => {
    setGroup(group.id);
    setPosts(group.posts);
  }, [group, registrations, personnel, groups]);

  return (
    <main>
      <Box className={classes.controls}>
        <h2>
          Manage &nbsp;
          {group.name}
        </h2>
        <Typography>
          <strong>Class Code:</strong>{' '}
          <a href={`/group/${group.code}`} target="_blank">
            {group.code}
          </a>
        </Typography>
      </Box>
      <div className={classes.groupForm}>
        <ButtonGroup>
          <Tooltip title="Email the class.">
            <Button color="primary" onClick={() => emailGroup()}>
              <EmailIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Post new content to group.">
            <Button color="primary" onClick={() => postToGroup()}>
              <PostAddIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>

        <Typography variant="h5" className={classes.title}>
          Students
        </Typography>
        <List>
          {groupStudents
            .sort((a, b) =>
              a.studentFirstName.localeCompare(b.studentFirstName)
            )
            .map((r) => (
              <GroupListItem key={r.id} registration={r} />
            ))}
        </List>

        {group.posts && group.posts.length > 0 ? (
          <Typography variant="h5" className={classes.title}>
            Posts
          </Typography>
        ) : (
          ''
        )}

        {posts
          ? posts.map((p, index) => (
              <PostListItem
                key={index}
                post={p}
                deletePost={() => deletePost(p)}
              />
            ))
          : ''}
      </div>
      <Dialog
        fullScreen
        open={postOpen}
        onClose={() => setPostOpen(false)}
        TransitionComponent={Transition}
      >
        <AddPost onClose={() => setPostOpen(false)} group={group} />
      </Dialog>
    </main>
  );
};

GroupManagerComponent.propTypes = {
  personnel: PropTypes.arrayOf(PropTypes.object).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    posts: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  registrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveGroup: PropTypes.func.isRequired,
};

const GroupListItem = ({ registration }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  function sentenceCase(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  return (
    <>
      <ListItem>
        <Tooltip title="View registration information">
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Tooltip>
        <ListItemText
          primary={`${registration.studentFirstName} ${registration.studentLastName}`}
          secondary={registration.course}
        />
        <ListItemSecondaryAction />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.nested}>
          {Object.keys(registration).map((col) => (
            <div key={col}>
              {col === 'availability' ? (
                <Typography className={classes.title}>
                  <strong>{`${sentenceCase(col)} :`}</strong>
                  <AvailabilityDisplay availability={registration[col]} />
                </Typography>
              ) : (
                <Typography className={classes.title}>
                  <strong>{`${sentenceCase(col)}`}</strong>
                  {`: ${registration[col]}`}
                </Typography>
              )}
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};

GroupListItem.propTypes = {
  registration: PropTypes.shape({
    studentFirstName: PropTypes.string,
    studentLastName: PropTypes.string,
    course: PropTypes.string,
  }).isRequired,
};

const PostListItem = ({ post, deletePost }) => {
  const classes = useStyles();

  const [deleteMode, setDeleteMode] = useState(false);

  function getDateString(date) {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(date);
  }

  function viewAttachment(attachment) {
    window.open(attachment.path);
  }

  const handleDelete = () => {
    deletePost(post);
    setDeleteMode(false);
  };

  return (
    <Paper className={classes.post}>
      <div>
        {deleteMode ? (
          <>
            <Typography color="secondary">
              Are you sure you'd like to delete this post?
              <Tooltip title="Yes">
                <IconButton onClick={() => handleDelete()}>
                  <CheckIcon color="secondary" />
                </IconButton>
              </Tooltip>{' '}
              &nbsp;
              <Tooltip title="No">
                <IconButton onClick={() => setDeleteMode(false)}>
                  <CancelIcon color="secondary" />
                </IconButton>
              </Tooltip>
              &nbsp;
            </Typography>
          </>
        ) : (
          <>
            <Tooltip title="Delete post">
              <IconButton onClick={() => setDeleteMode(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
      <div>
        <i>
          By&nbsp;
          {post.postAuthor}
        </i>
      </div>
      <div>
        <i>{getDateString(post.postDate)}</i>
      </div>
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
      {post.attachments && post.attachments.length > 0
        ? post.attachments.map((attachment) => (
            <Button
              color="primary"
              onClick={() => viewAttachment(attachment)}
              startIcon={<AttachmentIcon />}
            >
              {attachment.name}
            </Button>
          ))
        : ''}
    </Paper>
  );
};

PostListItem.propTypes = {
  post: PropTypes.shape({
    content: PropTypes.string,
    postAuthor: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
    postDate: PropTypes.number,
  }).isRequired,
};

const AvailabilityDisplay = ({ availability }) => {
  const classes = useStyles();

  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  function getDayString(day) {
    const arr = [];

    if (day.morning) arr.push('Morning');
    if (day.afternoon) arr.push('Afternoon');
    if (day.evening) arr.push('Evening');

    return arr.join(', ');
  }

  function titleCase(string) {
    const sentence = string.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i += 1) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1, 3);
    }

    return sentence;
  }

  return days
    .filter((day) => getDayString(availability[day]).length > 0)
    .map((day) => (
      <div key={day} className={classes.availabilityItem}>{`${titleCase(
        day
      )}: ${getDayString(availability[day])}`}</div>
    ));
};

AvailabilityDisplay.propTypes = {
  params: PropTypes.shape({
    data: PropTypes.shape({
      availability: PropTypes.shape({}),
    }),
  }).isRequired,
};

const mapStateToProps = (
  {
    personnel: { personnel },
    registrations: { registrations },
    groups: { groups },
  },
  ownProps
) => {
  const groupId = ownProps.match.params.id;

  const group = groups.find((gr) => gr.id === groupId);

  let regList = [];

  if (registrations) {
    regList = registrations.filter((reg) => !reg.archived);
  }

  return {
    groups,
    personnel,
    group: group || {},
    registrations: regList,
  };
};

const mapDispatchToProps = {
  saveGroup: saveGroupAction,
};

const GroupManager = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupManagerComponent)
);

export default GroupManager;
