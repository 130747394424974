import { SUCCESS } from '../../snackbar/successSnackbar';

export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const showSnackbar = (message, severity = SUCCESS) => (dispatch) => {
  dispatch({ type: SNACKBAR_SUCCESS, message, severity });
};

export const clearSnackbar = () => (dispatch) => {
  dispatch({ type: SNACKBAR_CLEAR });
};
