import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';

import Hidden from '@material-ui/core/Hidden';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import CopyrightIcon from '@material-ui/icons/Copyright';
import Home from './home/home';
import Dashboard from './dashboard/dashboard';

import Mission from './mission/mission';
import Programs from './programs/programs';

import People from './people/people';
import ScrollToTopController from './shared/scrollToTopController';

import { getAllPersonnel as getAllPersonnelAction } from './redux/actions/personnelActions';
import { getAllTerms as getAllTermsAction } from './redux/actions/termActions';
import { getAllCourses as getAllCoursesAction } from './redux/actions/courseActions';
import { getAllForms as getAllFormsAction } from './redux/actions/formActions';
import { getAllRegistrations as getAllRegistrationsAction } from './redux/actions/registrationActions';
import { getAllGroups as getAllGroupsAction } from './redux/actions/groupActions';
import { resumeSession as resumeSessionAction } from './redux/actions/authActions';
import { getAllInvoices as getAllInvoicesAction } from './redux/actions/invoiceActions';
import ViewProgramCourses from './courses/viewProgramCourses';
import RegistrationForm from './registration/registrationForm';
import Expectations from './registration/expectations';
import SuccessSnackbar from './snackbar/successSnackbar';
import SignIn from './signin/signIn';
import RegistrationConfirmation from './registration/registrationConfirmation';

import './App.scss';
import './navbar/navBar.scss';
import Person from './people/person';
import GroupVerification from './group/groupVerification';
import Group from './group/group';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    padding: 0,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#333333',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    width: '100vw',
    padding: 0,
    margin: 0,
    minHeight: 'calc(100vh - 250px)',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  footer: {
    width: '100vw',
    padding: 0,
    margin: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  footerShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawerNavLink: {
    textDecoration: 'none',
  },
}));

const AppComponent = ({
  getAllPersonnel,
  getAllTerms,
  getAllCourses,
  getAllForms,
  getAllRegistrations,
  getAllGroups,
  getAllInvoices,
  resumeSession,
  authenticated,
  currentUser,
}) => {
  // Load in all important data
  useEffect(() => {
    getAllPersonnel();
    getAllTerms();
    getAllCourses();
    getAllForms();
    getAllRegistrations();
    getAllGroups();
    getAllInvoices();
  }, [
    getAllPersonnel,
    getAllTerms,
    getAllCourses,
    getAllForms,
    getAllRegistrations,
    getAllGroups,
    getAllInvoices,
  ]);

  useEffect(() => {
    resumeSession();
  }, [resumeSession]);

  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SuccessSnackbar />
      <Router>
        <ScrollToTopController />
        <div className="app">
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar className="toolBar">
              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Link to="/">
                <img
                  src="/assets/images/logo_small.png"
                  alt="logo"
                  className="logo"
                />
              </Link>
              <div className="spacer" />
              <Hidden smDown>
                <Typography>
                  <Link className="navLink" to="/mission">
                    About
                  </Link>
                </Typography>
                <Typography>
                  <Link className="navLink" to="/programs">
                    Register
                  </Link>
                </Typography>
                <Typography>
                  <Link className="navLink" to="/people">
                    People
                  </Link>
                </Typography>
                <Typography>
                  <Link className="navLink" to="/groupcode">
                    Group
                  </Link>
                </Typography>
                {authenticated ? (
                  <>
                    <Typography>
                      <Link className="navLink" to="/dashboard">
                        Dashboard
                      </Link>
                    </Typography>
                    <Avatar alt="Remy Sharp" src={currentUser.image} />
                  </>
                ) : (
                  <Typography>
                    <Link className="navLink" to="/signin">
                      Sign In
                    </Link>
                  </Typography>
                )}
              </Hidden>
            </Toolbar>
          </AppBar>
          <Toolbar
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          />
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              <Link
                to="/mission"
                onClick={() => setOpen(false)}
                className={classes.drawerNavLink}
              >
                <ListItem button key="About">
                  <Typography>About</Typography>
                </ListItem>
              </Link>
              <Link
                to="/programs"
                onClick={() => setOpen(false)}
                className={classes.drawerNavLink}
              >
                <ListItem button key="Register">
                  <Typography>Register</Typography>
                </ListItem>
              </Link>
              <Link
                to="/people"
                onClick={() => setOpen(false)}
                className={classes.drawerNavLink}
              >
                <ListItem button key="Resources">
                  <Typography>People</Typography>
                </ListItem>
              </Link>
              <Link
                to="/groupcode"
                onClick={() => setOpen(false)}
                className={classes.drawerNavLink}
              >
                <ListItem button key="Resources">
                  <Typography>Group</Typography>
                </ListItem>
              </Link>
              {authenticated ? (
                <Link
                  to="/dashboard"
                  onClick={() => setOpen(false)}
                  className={classes.drawerNavLink}
                >
                  <ListItem button key="Dashboard">
                    <Typography>Dashboard</Typography>
                  </ListItem>
                </Link>
              ) : (
                <Link
                  to="/signin"
                  onClick={() => setOpen(false)}
                  className={classes.drawerNavLink}
                >
                  <ListItem button key="Sign In">
                    <Typography>Sign In</Typography>
                  </ListItem>
                </Link>
              )}
            </List>
          </Drawer>

          <Container
            maxWidth={false}
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <Route exact path="/" component={Home} />
            <Route exact path="/people" component={People} />
            <Route exact path="/person/:id" component={Person} />
            <Route exact path="/mission" component={Mission} />
            <Route exact path="/programs" component={Programs} />
            <Route exact path="/courses/:slug" component={ViewProgramCourses} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/dashboard/:slug" component={Dashboard} />
            <Route path="/dashboard/:slug/:id" component={Dashboard} />
            <Route path="/courses/:id/register" component={RegistrationForm} />
            <Route exact path="/expectations" component={Expectations} />
            <Route
              exact
              path="/registered"
              component={RegistrationConfirmation}
            />
            <Route exact path="/groupcode" component={GroupVerification} />
            <Route exact path="/group/:code" component={Group} />
          </Container>

          <Footer
            className={clsx(classes.footer, {
              [classes.footerShift]: open,
            })}
          />
        </div>
      </Router>
    </>
  );
};

AppComponent.propTypes = {
  getAllPersonnel: PropTypes.func.isRequired,
  getAllTerms: PropTypes.func.isRequired,
  getAllCourses: PropTypes.func.isRequired,
  getAllForms: PropTypes.func.isRequired,
  getAllRegistrations: PropTypes.func.isRequired,
  getAllGroups: PropTypes.func.isRequired,
  getAllInvoices: PropTypes.func.isRequired,
  resumeSession: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    image: PropTypes.string,
  }),
};

AppComponent.defaultProps = {
  currentUser: null,
};

const footerStyles = makeStyles(() => ({
  root: {
    marginTop: '50px',
    backgroundColor: '#333333',
    color: 'white',
    textAlign: 'center',
    padding: '20px',
  },
  whiteText: {
    color: 'white',
    verticalAlign: 'middle',
  },
  whiteLink: {
    color: 'white',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const Footer = () => {
  const classes = footerStyles();

  const email = () => {
    window.location.href = 'mailto:info@ccminds.com';
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.whiteText}>
        Copyright
        <CopyrightIcon fontSize="small" /> 2020 Cultivating Creative Minds. All
        rights reserved.
      </Typography>
      <Typography className={classes.whiteLink} onClick={() => email()}>
        info@ccminds.com
      </Typography>
      <Typography className={classes.whiteText}>(310) 918-5780</Typography>
      <Typography className={classes.whiteText}>
        PO Box 220938, Newhall, CA 91322
      </Typography>
    </div>
  );
};

const mapStateToProps = ({
  auth: { authenticated },
  auth: { currentUser },
}) => ({
  authenticated,
  currentUser,
});

const mapDispatchToProps = {
  getAllPersonnel: getAllPersonnelAction,
  getAllTerms: getAllTermsAction,
  getAllCourses: getAllCoursesAction,
  getAllForms: getAllFormsAction,
  getAllRegistrations: getAllRegistrationsAction,
  getAllGroups: getAllGroupsAction,
  resumeSession: resumeSessionAction,
  getAllInvoices: getAllInvoicesAction,
};

const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);

export default App;
