import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { IconButton, Button, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  groupForm: {
    paddingRight: 30,
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
}));

const GroupCreator = ({
  onClose,
  onSave,
  personnel,
  students,
  onStudentsChanged,
  columns,
}) => {
  const classes = useStyles();

  const [allInSameGroup, setAllInSameGroup] = useState(true);
  const [newGroupName, setNewGroupName] = useState();
  const [newGroupTeacher, setNewGroupTeacher] = useState([]);

  useEffect(() => {
    checkAllInGroupForSameCourse();
  });

  useEffect(() => {
    setNewGroupTeacher(personnel[0]);
  }, [personnel]);

  const checkAllInGroupForSameCourse = () => {
    const courses = students.map((g) => g.courseName);
    const distinctCourses = [...new Set(courses)];

    setAllInSameGroup(distinctCourses.length < 2);
  };

  const handleSaveClick = () => {
    const newGroup = {
      id: -1,
      name: newGroupName,
      teacherId: newGroupTeacher,
    };

    onSave(newGroup);
  };

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Tooltip title="Close without saving">
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" className={classes.title}>
            Create New Group
          </Typography>
          <Tooltip title="Save and close">
            <Button
              autoFocus
              color="inherit"
              onClick={handleSaveClick}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <div className={classes.groupForm}>
        <TextField
          id="groupName"
          label="Group Name"
          variant="outlined"
          onChange={(e) => setNewGroupName(e.target.value)}
          fullWidth
        />

        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-age-native-simple">
            {' '}
            Teaching Artist{' '}
          </InputLabel>
          <Select
            native
            onChange={(e) => setNewGroupTeacher(e.target.value)}
            label="teaching artist"
          >
            {personnel
              .filter((p) => p.active)
              .map((p) => (
                <option value={p.id} key={p.id}>
                  {' '}
                  {p.firstName} {p.lastName}{' '}
                </option>
              ))}
          </Select>
        </FormControl>
        <Typography variant="h5" className={classes.title}>
          Students
        </Typography>
        <List>
          {students.map((r) => (
            <GroupListItem
              key={r.id}
              registration={r}
              columns={columns}
              addToGroup={(r) => onStudentsChanged(r)}
            />
          ))}
        </List>
        {allInSameGroup ? (
          <div />
        ) : (
          <Alert severity="error">
            Not all of these registrations signed up for the same course.
          </Alert>
        )}
      </div>
    </>
  );
};

GroupCreator.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  personnel: PropTypes.arrayOf(PropTypes.object).isRequired,
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
  onStudentsChanged: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const GroupListItem = ({ registration, columns, addToGroup }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem>
        <Tooltip title="View registration information">
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Tooltip>
        <ListItemText
          primary={`${registration.studentFirstName} ${registration.studentLastName}`}
          secondary={registration.courseName}
        />
        <ListItemSecondaryAction>
          <Tooltip title="Remove from group">
            <IconButton
              variant="outlined"
              onClick={() => addToGroup(registration)}
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.nested}>
          {columns.map((col) =>
            col.field && col.field !== '' ? (
              <div key={col.field}>
                {col.field === 'availability' ? (
                  <Typography className={classes.title}>
                    <strong>{`${col.headerName} :`}</strong>
                    <AvailabilityDisplay
                      availability={registration[col.field]}
                    />
                  </Typography>
                ) : (
                  <Typography className={classes.title}>
                    <strong>{`${col.headerName}`}</strong>
                    {`: ${registration[col.field]}`}
                  </Typography>
                )}
              </div>
            ) : (
              ''
            )
          )}
        </div>
      </Collapse>
    </>
  );
};

GroupListItem.propTypes = {
  registration: PropTypes.shape({
    studentFirstName: PropTypes.string,
    studentLastName: PropTypes.string,
    courseName: PropTypes.string,
  }).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  addToGroup: PropTypes.func.isRequired,
};

const AvailabilityDisplay = ({ availability }) => {
  const classes = useStyles();

  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  function getDayString(day) {
    const arr = [];

    if (day.morning) arr.push('Morning');
    if (day.afternoon) arr.push('Afternoon');
    if (day.evening) arr.push('Evening');

    return arr.join(', ');
  }

  function titleCase(string) {
    const sentence = string.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i += 1) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1, 3);
    }

    return sentence;
  }

  return days
    .filter((day) => getDayString(availability[day]).length > 0)
    .map((day) => (
      <div key={day} className={classes.availabilityItem}>{`${titleCase(
        day
      )}: ${getDayString(availability[day])}`}</div>
    ));
};

AvailabilityDisplay.propTypes = {
  params: PropTypes.shape({
    data: PropTypes.shape({
      availability: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default GroupCreator;
