import { combineReducers } from 'redux';
import venues from './venueReducer';
import programs from './programReducer';
import courses from './courseReducer';
import terms from './termReducer';
import personnel from './personnelReducer';
import forms from './formReducer';
import registrations from './registrationReducer';
import groups from './groupReducer';
import ui from './uiReducer';
import auth from './authReducer';
import invoices from './invoiceReducer';

const rootReducer = combineReducers({
  venues,
  programs,
  courses,
  terms,
  personnel,
  forms,
  registrations,
  groups,
  ui,
  auth,
  invoices,
});

export default rootReducer;
