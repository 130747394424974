import PersonnelService from '../../services/personnelService';
import { showSnackbar } from './snackbarActions';
import { ERROR } from '../../snackbar/successSnackbar';

import { DEPENDENCY } from '../../services/errors';

/*
 * Action Types
 */
export const ADD_PERSON = 'ADD_PERSON';
export const ADD_PERSON_SUCCESS = 'ADD_PERSON_SUCCESS';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';

export const EDIT_PERSON = 'EDIT_PERSON';

export const DELETE_PERSON = 'DELETE_PERSON';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';

export const GET_PERSON_BY_ID = 'GET_PERSON_BY_ID';

export const GET_ALL_PERSONNEL = 'GET_ALL_PERSON';
export const GET_ALL_PERSONNEL_SUCCESS = 'GET_ALL_PERSON_SUCCESS';

/*
 * Action creators
 */

export const editPerson = (person) => ({ type: EDIT_PERSON, person });

export const getPersonById = (id) => ({ type: GET_PERSON_BY_ID, id });

/*
 * Action creators
 */
export const deletePersonSuccess = (person) => ({ type: DELETE_PERSON_SUCCESS, person });
export const deletePerson = (person) => (dispatch) => PersonnelService.deletePerson(person).then(() => {
  dispatch(showSnackbar('Successfully deleted person.'));
  dispatch(deletePersonSuccess(person));
}).catch((error) => {
  if (error.type === DEPENDENCY) {
    dispatch(showSnackbar('Can not delete person. Make sure no other groups are dependent on this person.', ERROR));
  }
  // throw error
});

export const addPersonSuccess = (person) => ({ type: ADD_PERSON_SUCCESS, person });
export const updatePersonSuccess = (person) => ({ type: UPDATE_PERSON_SUCCESS, person });
export const savePerson = (person) => (dispatch) => {
  if (!person.id) {
    return PersonnelService.addPerson(person).then((newPerson) => {
      dispatch(showSnackbar('Successfully added person.'));
      dispatch(addPersonSuccess(newPerson));
    }).catch((error) => {
      throw error;
    });
  }
  return PersonnelService.updatePerson(person).then((person) => {
    dispatch(showSnackbar('Successfully updated person.'));
    dispatch(updatePersonSuccess(person));
  }).catch((error) => {
    throw error;
  });
};

export const getAllPersonnelSuccess = (personnel) => ({ type: GET_ALL_PERSONNEL_SUCCESS, personnel });
export const getAllPersonnel = () => (dispatch) => PersonnelService.getAllPersonnel().then((personnel) => {
  dispatch(getAllPersonnelSuccess(personnel));
}).catch((error) => {
  throw error;
});
