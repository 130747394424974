import { db, storage } from '../firebase';

import RegistrationService from './registrationService';
import { DEPENDENCY } from './errors';

const getAllTerms = () =>
  new Promise((resolve) => {
    db.collection('terms').onSnapshot((snapshot) => {
      const allTerms = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      resolve(allTerms);
    });
  });

const addTerm = (term) =>
  new Promise((resolve) => {
    db.collection('terms')
      .add(term)
      .then((t) => {
        term.id = t.id;

        resolve(term);
      });
  });

const updateTerm = (term) =>
  new Promise((resolve) => {
    db.collection('terms')
      .doc(term.id)
      .set(term)
      .then((t) => {
        resolve(term);
      });
  });

const getCoursesByTerm = (term) =>
  new Promise((resolve) => {
    const termRef = db.collection('/terms').doc(term.id);

    db.collection('courses')
      .where('term', '==', termRef)
      .onSnapshot((snapshot) => {
        const allCourses = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedCourses = allCourses.sort((a, b) => {
          if (a.index > b.index) {
            return 1;
          }

          return -1;
        });

        resolve(sortedCourses);
      });
  });

const deleteTerm = (term) =>
  new Promise((resolve, reject) => {
    getCoursesByTerm(term).then((courses) => {
      // Ensure there are no courses associated with the term
      if (courses.length > 0) {
        reject({ type: DEPENDENCY, courses });
      } else {
        db.collection('terms')
          .doc(term.id)
          .delete()
          .then(() => {
            resolve(term);
          });
      }
    });
  });

const getAllPrograms = () =>
  new Promise((resolve) => {
    console.log('Calling for programs..');
    db.collection('programs').onSnapshot((snapshot) => {
      const allPrograms = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log('Received programs:', allPrograms);

      resolve(allPrograms);
    });
  });

const getAllCourses = () =>
  new Promise((resolve) => {
    db.collection('courses').onSnapshot((snapshot) => {
      const allCourses = snapshot.docs.map((doc) => {
        const index = doc.data().index || 0;

        return {
          ...doc.data(),
          id: doc.id,
          index,
        };
      });

      const sortedCourses = allCourses.sort((a, b) => {
        if (a.index > b.index) {
          return 1;
        }

        return -1;
      });

      resolve(sortedCourses);
    });
  });

const getCoursesByForm = (form) =>
  new Promise((resolve) => {
    const formRef = db.collection('/forms').doc(form.id);

    db.collection('courses')
      .where('form', '==', formRef)
      .onSnapshot((snapshot) => {
        const allCourses = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedCourses = allCourses.sort((a, b) => {
          if (a.index > b.index) {
            return 1;
          }

          return -1;
        });

        resolve(sortedCourses);
      });
  });

const addCourseToDb = (course) =>
  new Promise((resolve) => {
    getAllCourses().then((courses) => {
      course.index = courses.length;

      db.collection('courses')
        .add(course)
        .then((c) => {
          course.id = c.id;

          resolve(course);
        });
    });
  });

const addCourse = (course) => {
  course.type = 'ONLINE';

  if (typeof course.term !== 'object') {
    course.term = db.collection('/terms').doc(course.term);
  }
  if (typeof course.form !== 'object') {
    course.form = db.collection('/forms').doc(course.form);
  }

  delete course.id;

  if (course.image && typeof course.image !== 'string') {
    return new Promise((resolve) => {
      const imageName = course.image.name;

      const uploadTask = storage
        .ref(`/images/courses/${imageName}`)
        .put(course.image);
      uploadTask.on(
        'state_changed',
        (snapShot) => {
          // takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          // catches the errors
          console.log(err);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref('images/courses')
            .child(imageName)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              course.image = fireBaseUrl;

              addCourseToDb(course).then(() => resolve(course));
            });
        }
      );
    });
  }
  return addCourseToDb(course);
};

const updateCourseOrder = (courses) =>
  new Promise((resolve) => {
    const batch = db.batch();

    courses.forEach((course) => {
      const ref = db.collection('courses').doc(course.id);
      batch.update(ref, { index: course.index });
    });

    batch.commit().then(() => resolve(courses));
  });

const updateCourse = (course) => {
  if (typeof course.term !== 'object') {
    course.term = db.collection('/terms').doc(course.term);
  }
  if (typeof course.form !== 'object') {
    course.form = db.collection('/forms').doc(course.form);
  }

  if (course.image && typeof course.image !== 'string') {
    return new Promise((resolve) => {
      const imageName = course.image.name;

      const uploadTask = storage
        .ref(`/images/courses/${imageName}`)
        .put(course.image);
      uploadTask.on(
        'state_changed',
        (snapShot) => {
          // takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          // catches the errors
          console.log(err);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:

          storage
            .ref('images/courses')
            .child(imageName)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              course.image = fireBaseUrl;

              db.collection('courses')
                .doc(course.id)
                .set(course)
                .then((c) => {
                  resolve(course);
                });
            });
        }
      );
    });
  }
  return new Promise((resolve) => {
    db.collection('courses')
      .doc(course.id)
      .set(course)
      .then((c) => {
        resolve(course);
      });
  });
};

const deleteCourse = (course) =>
  new Promise((resolve, reject) => {
    RegistrationService.getRegistrationsByCourse(course).then(
      (registrations) => {
        // Ensure there are no courses associated with the term
        if (registrations.length > 0) {
          reject({ type: DEPENDENCY, registrations });
        } else {
          db.collection('courses')
            .doc(course.id)
            .delete()
            .then(() => {
              resolve(course);
            });
        }
      }
    );
  });

const CourseService = {
  getAllPrograms,
  getAllCourses,
  getCoursesByTerm,
  getCoursesByForm,
  addCourse,
  updateCourse,
  deleteCourse,
  getAllTerms,
  addTerm,
  updateTerm,
  deleteTerm,
  updateCourseOrder,
};

export default CourseService;
