import {
  GET_ALL_PERSONNEL_SUCCESS, ADD_PERSON_SUCCESS, UPDATE_PERSON_SUCCESS, DELETE_PERSON_SUCCESS,
} from '../actions/personnelActions';

import initialState from './initialState';

const personnel = (state = initialState.personnel, action) => {
  switch (action.type) {
    case GET_ALL_PERSONNEL_SUCCESS:
      return {
        ...state,
        personnel: action.personnel,
      };
    case ADD_PERSON_SUCCESS:
      return {
        ...state,
        personnel: [...state.personnel, action.person],
      };
    case UPDATE_PERSON_SUCCESS:
      return {
        personnel: state.personnel.map((person) => {
          if (person.id !== action.person.id) {
            return person;
          }

          return action.person;
        }),
      };
    case DELETE_PERSON_SUCCESS:
      return {
        ...state,
        personnel: state.personnel.filter((p) => p.id !== action.person.id),
      };
    default:
      return state;
  }
};

export default personnel;
