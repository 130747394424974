import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { TextField, Button, Container } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import { signIn as signInAction } from '../redux/actions/authActions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const SignInComponent = ({ signIn, history }) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    signIn(username, password).then(
      () => history.push('/dashboard/'),
      (e) => {
        setError(e.message);
      },
    );
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <Container>
      <h2>Sign In</h2>
      {error ? <Alert severity="error" onClose={() => clearError()}>{error}</Alert> : ''}
      <form onSubmit={handleSubmit} className={classes.root}>
        <div className="formElement">
          <TextField
            id="userName"
            label="Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="formElement">
          <TextField
            id="password"
            label="Password"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>

        <Button type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
      </form>
    </Container>
  );
};

SignInComponent.propTypes = {
  signIn: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  signIn: signInAction,
};

const SignIn = withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInComponent));

export default SignIn;
