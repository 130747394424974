import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import {
  Paper, Box,
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HomeIcon from '@material-ui/icons/Home';
import { signOut as signOutAction } from '../redux/actions/authActions';

import AddCourse from './courses/addCourse';
import ViewCourses from './courses/viewCourses';
import AddTerm from './courses/addTerm';
import ViewTerms from './courses/viewTerms';
import AddPerson from './personnel/addPerson';
import ViewPersonnel from './personnel/viewPersonnel';

import './dashboard.scss';
import ViewForms from './forms/viewForms';
import AddForm from './forms/addForm';
import ViewRegistrations from './registrations/viewRegistrations';
import ViewGroups from './groups/viewGroups';
import ViewMyGroups from './groups/viewMyGroups';
import ManageGroup from './groups/groupManager';
import EditRegistration from './registrations/editRegistration';

const collection = [
  {
    id: 300,
    name: 'Terms',
    enabled: true,
    items: [
      {
        id: 1,
        name: 'Terms',
        slug: 'view-terms',
        component: ViewTerms,
        levels: ['Admin'],
      },
      {
        id: 2,
        hide: true,
        name: 'Add Term',
        slug: 'add-term',
        component: AddTerm,
        levels: ['Admin'],
      },
    ],
  },
  {
    id: 100,
    name: 'Courses',
    enabled: true,
    items: [
      {
        id: 3,
        name: 'Courses',
        slug: 'view-courses',
        component: ViewCourses,
        levels: ['Admin'],
      },
      {
        id: 4,
        hide: true,
        name: 'Add Course',
        slug: 'add-course',
        component: AddCourse,
        levels: ['Admin'],
      },
      {
        id: 13,
        name: 'Forms',
        slug: 'view-forms',
        component: ViewForms,
        levels: ['Admin'],
      },
      {
        id: 14,
        hide: true,
        name: 'Add Form',
        slug: 'add-form',
        component: AddForm,
        levels: ['Admin'],
      },
    ],
  },
  {
    id: 600,
    name: 'Forms',
    enabled: true,
    items: [
      {
        id: 13,
        name: 'Forms',
        slug: 'view-forms',
        component: ViewForms,
        levels: ['Admin'],
      },
      {
        id: 14,
        hide: true,
        name: 'Add Form',
        slug: 'add-form',
        component: AddForm,
        levels: ['Admin'],
      },
    ],
  },
  {
    id: 500,
    name: 'Groups',
    enabled: true,
    items: [
      {
        id: 7,
        name: 'Groups',
        slug: 'view-groups',
        component: ViewGroups,
        levels: ['Admin'],
      },
      {
        id: 20,
        hide: true,
        name: 'My Groups',
        slug: 'my-groups',
        component: ViewMyGroups,
        levels: ['Admin', 'Teaching Artist'],
      },
      {
        id: 9,
        hide: true,
        name: 'Manage Group',
        slug: 'manage-group',
        component: ManageGroup,
        levels: ['Admin', 'Teaching Artist'],
      },
    ],
  },
  {
    id: 200,
    name: 'Registrations',
    enabled: true,
    items: [
      {
        id: 5,
        name: 'Registrations',
        slug: 'view-registrations',
        component: ViewRegistrations,
        levels: ['Admin'],
      },
      {
        id: 6,
        hide: true,
        name: 'Add Registration',
        slug: 'add-registration',
        levels: ['Admin'],
      },
      {
        id: 8,
        hide: true,
        name: 'Edit Registration',
        slug: 'edit-registration',
        component: EditRegistration,
        levels: ['Admin'],
      },
    ],
  },
  {
    id: 400,
    name: 'Personnel',
    enabled: true,
    items: [
      {
        id: 10,
        name: 'Personnel',
        slug: 'view-teaching-artists',
        component: ViewPersonnel,
        levels: ['Admin'],
      },
      {
        id: 11,
        hide: true,
        name: 'Add Person',
        slug: 'add-person',
        component: AddPerson,
        levels: ['Admin'],
      },
      {
        id: 12,
        hide: true,
        name: 'Job Applications',
        slug: 'job-applications',
        levels: ['Admin'],
      },
    ],
  },
];

const isAvailable = (groupItem, currentUser) => (currentUser && groupItem.levels ? groupItem.levels.indexOf(currentUser.type) > -1 : false);

const hasAvailableChildren = (group, currentUser) => {
  const available = group.items.filter((groupItem) => isAvailable(groupItem, currentUser));

  return available.length > 0;
};

const DashboardComponent = ({
  collection, page = null, signOut, selectedSection, history,
}) => {
  const [value, setValue] = useState(0);

  const setSelectedItem = (item) => {
    if (item) {
      history.push(`/dashboard/${item.slug}`);
    } else {
      history.push('/dashboard/');
    }
  };

  const handleSignOut = () => {
    signOut().then(() => history.push('/'));
  };

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setValue(false);
      setSelectedItem(null);
    } else if (newValue === collection.length + 1) {
      handleSignOut();
    } else {
      setValue(newValue);
      setSelectedItem(collection[newValue - 1].items[0]);
    }
  };

  useEffect(() => {
    if (selectedSection > -1) {
      setValue(selectedSection + 1);
    } else {
      setValue(false);
    }
  }, [selectedSection]);

  return (
    <div>
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab key={1000} icon={<HomeIcon />} />
          { collection.map((section) => <Tab key={section.id} label={section.name} />) }
          <Tab key={1001} label="Sign Out" />
        </Tabs>
      </Paper>
      <Box>
        <DashboardContent selectedItem={page} />
      </Box>
    </div>
  );
};

DashboardComponent.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.shape({
    id: PropTypes.number,
  }),
  signOut: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

DashboardComponent.defaultProps = {
  page: null,
};

const useStyles = makeStyles((theme) => ({
  controls: {
    marginLeft: '20px',
  },
}));

const DashboardContent = ({ selectedItem }) => {
  const classes = useStyles();

  if (selectedItem) {
    if (selectedItem.component) {
      return <selectedItem.component />;
    }
    return (
      <>
        <h1>{selectedItem.name}</h1>
      </>
    );
  }
  return (
    <>
      <Box className={classes.controls}>
        <h1>Dashboard</h1>
      </Box>

      <ViewMyGroups />
    </>
  );
};

DashboardContent.propTypes = {
  selectedItem: PropTypes.shape({
    name: PropTypes.string,
    component: PropTypes.any,
  }),
};

DashboardContent.defaultProps = {
  selectedItem: null,
};

const getPageBySlug = (slug) => {
  let page = null;

  collection.forEach((group) => {
    group.items.forEach((p) => {
      if (p.slug === slug) {
        page = p;
      }
    });
  });

  return page;
};

const getSelectedSectionIndex = (coll, selectedItem) => {
  const index = coll.findIndex((section) => section.items.find((item) => item === selectedItem) != null);

  return index;
};

const mapStateToProps = ({ auth: { currentUser } }, ownProps) => {
  const slug = ownProps.match.params ? ownProps.match.params.slug : null;

  const selectedItem = slug && collection.length > 0 ? getPageBySlug(slug) : null;

  let filteredCollection = collection.filter((navGroup) => navGroup.enabled && hasAvailableChildren(navGroup, currentUser));

  filteredCollection = filteredCollection.map((section) => (
    {
      ...section,
      items: section.items.filter((item) => isAvailable(item, currentUser)),
    }
  ));

  const selectedSectionIndex = getSelectedSectionIndex(filteredCollection, selectedItem);
  return {
    collection: filteredCollection,
    page: selectedItem,
    selectedSection: selectedSectionIndex,
  };
};

const mapDispatchToProps = {
  signOut: signOutAction,
};

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);

export default Dashboard;
