/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

import { Link } from 'react-router-dom';

const borderRadius = '30px';

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    fontWeight: 600,
    fontSize: '48px',
  },
  programInfo: {
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'middle',
    alignItems: 'middle',
    width: '100%',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0 0 0 0',
      padding: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: `${borderRadius} ${borderRadius} ${borderRadius} ${borderRadius}`,
      padding: '20px',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: `${borderRadius} ${borderRadius} ${borderRadius} ${borderRadius}`,
      padding: '50px',
    },
  },
  programDetails: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'middle',
    alignItems: 'start',
  },
  coursesButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'middle',
    width: '100%',
  },
  registerButton: {
    borderRadius,
    padding: '20px 30px 20px 30px',
  },
  linkText: {
    textDecoration: 'none',
  },
}));

const SulphurSprings = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.programInfo}>
        <div className={classes.programDetails}>
          <Typography component="span" className={classes.linkText}>
            <Typography variant="h5" className={classes.title}>
              Sulphur Springs
            </Typography>
          </Typography>
        </div>
        <div className={classes.coursesButton}>
          <Link
            color="inherit"
            to="/courses/sulphurSprings"
            className={classes.linkText}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.registerButton}
            >
              View Courses
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SulphurSprings;
