import RegistrationService from '../../services/registrationService';
import { showSnackbar } from './snackbarActions';

/*
 * Action Types
 */
export const ADD_REGISTRATION = 'ADD_REGISTRATION';
export const ADD_REGISTRATION_SUCCESS = 'ADD_REGISTRATION_SUCCESS';
export const UPDATE_REGISTRATION_SUCCESS = 'UPDATE_REGISTRATION_SUCCESS';

export const SAVE_PROGRESS = 'SAVE_PROGRESS';

export const GET_ALL_REGISTRATIONS = 'GET_ALL_REGISTRATIONS';
export const GET_ALL_REGISTRATIONS_SUCCESS = 'GET_ALL_REGISTRATIONS_SUCCESS';

export const ADD_REGISTRATION_FILTER = 'ADD_REGISTRATION_FILTER';
export const DELETE_REGISTRATION_FILTER = 'DELETE_REGISTRATION_FILTER';

/*
 * Action creators
 */
export const addRegistrationSuccess = (registration) => ({ type: ADD_REGISTRATION_SUCCESS, registration });
export const updateRegistrationSuccess = (registration) => ({ type: UPDATE_REGISTRATION_SUCCESS, registration });
export const saveRegistration = (registration) => (dispatch) => {
  if (!registration.id) {
    return RegistrationService.addRegistration(registration).then((newRegistration) => {
      dispatch(showSnackbar('Successfully added registration.'));
      dispatch(addRegistrationSuccess(newRegistration));
    }).catch((error) => {
      throw error;
    });
  }
  return RegistrationService.updateRegistration(registration).then(() => {
    dispatch(showSnackbar('Successfully updated registration.'));
    dispatch(updateRegistrationSuccess(registration));
  }).catch((error) => {
    throw error;
  });
};

export const saveProgress = (registration) => ({ type: SAVE_PROGRESS, registration });

export const getAllRegistrationsSuccess = (registrations) => ({ type: GET_ALL_REGISTRATIONS_SUCCESS, registrations });
export const getAllRegistrations = () => (dispatch) => RegistrationService.getAllRegistrations().then((registrations) => {
  dispatch(getAllRegistrationsSuccess(registrations));
}).catch((error) => {
  throw error;
});

export const addRegistrationFilter = (filter) => ({ type: ADD_REGISTRATION_FILTER, filter });
export const deleteRegistrationFilter = (filter) => ({ type: DELETE_REGISTRATION_FILTER, filter });
