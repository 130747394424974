import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
  title: {
    width: '100%',
    fontWeight: 600,
    fontSize: '48px',
  },
  subTitle: {
    width: '100%',
    fontWeight: 600,
    fontSize: '24px',
  },
  content: {
    width: '100%',
    fontWeight: 400,
    fontSize: '14px',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
}));

const Board = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.headline}>Board</Typography>
      <div>
        <div>
          <Typography className={classes.subTitle}>Roxanne Jeppesen</Typography>
          <Typography className={classes.content}>
            Music Educator, Musician
          </Typography>
        </div>
        <div>
          <Typography className={classes.subTitle}>
            Moira McCarty Lamb
          </Typography>
          <Typography className={classes.content}>Music Educator</Typography>
        </div>
        <div>
          <Typography className={classes.subTitle}>Jane D’Anna</Typography>
          <Typography className={classes.content}>Principal</Typography>
        </div>
      </div>
    </>
  );
};

export default Board;
