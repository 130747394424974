import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';

const borderRadius = '5px';

const useStyles = makeStyles((theme) => ({
  availabilityHeaderCell: {
    textAlign: 'center',
    width: '150px',
  },
  availability: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #bdbdbd',
    borderRadius: `${borderRadius} ${borderRadius} ${borderRadius} ${borderRadius}`,
    padding: '18.5px 14px 18.5px 14px',
    marginBottom: '10px',
  },
  availabilityTable: {
    alignSelf: 'center',
  },
}));

const getBlankAvailability = () => ({
  sunday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  monday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  tuesday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  wednesday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  thursday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  friday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  saturday: {
    morning: false,
    afternoon: false,
    evening: false,
  },
});

const AvailabilityField = ({ values }) => {
  const classes = useStyles();

  const [avail, setAvail] = useState(values);

  useEffect(() => {
    values.availability = values.availability || getBlankAvailability();
    setAvail({ ...values });
  }, [values]);

  const handleChange = (day, time) => {
    values.availability[day][time] = !values.availability[day][time];

    setAvail({ ...values });
  };

  if (!values || !values.availability) {
    return <div />;
  }

  return (
    <div className={classes.availability}>
      <Typography><strong>Availability</strong></Typography>
      <Typography>
        Please check all the boxes for the days and times that
        &nbsp;
        <strong>you are available.</strong>
        {' '}
        This does not guarantee or commit you to any of these times. This is to assist us in placing your student in a time that is most convenient for you. Final schedule will be determined between students and their teacher.
      </Typography>
      <br />
      <table className={classes.availabilityTable}>
        <thead>
          <tr>
            <td />
            <td className={classes.availabilityHeaderCell}>Sun</td>
            <td className={classes.availabilityHeaderCell}>Mon</td>
            <td className={classes.availabilityHeaderCell}>Tue</td>
            <td className={classes.availabilityHeaderCell}>Wed</td>
            <td className={classes.availabilityHeaderCell}>Thur</td>
            <td className={classes.availabilityHeaderCell}>Fri</td>
            <td className={classes.availabilityHeaderCell}>Sat</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Morning (8am-12pm)</td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.sunday.morning}
                onChange={() => handleChange('sunday', 'morning')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.monday.morning}
                onChange={() => handleChange('monday', 'morning')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.tuesday.morning}
                onChange={() => handleChange('tuesday', 'morning')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.wednesday.morning}
                onChange={() => handleChange('wednesday', 'morning')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.thursday.morning}
                onChange={() => handleChange('thursday', 'morning')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.friday.morning}
                onChange={() => handleChange('friday', 'morning')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.saturday.morning}
                onChange={() => handleChange('saturday', 'morning')}
              />
            </td>
          </tr>
          <tr>
            <td>Afternoon (12pm-5pm)</td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.sunday.afternoon}
                onChange={() => handleChange('sunday', 'afternoon')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.monday.afternoon}
                onChange={() => handleChange('monday', 'afternoon')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.tuesday.afternoon}
                onChange={() => handleChange('tuesday', 'afternoon')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.wednesday.afternoon}
                onChange={() => handleChange('wednesday', 'afternoon')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.thursday.afternoon}
                onChange={() => handleChange('thursday', 'afternoon')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.friday.afternoon}
                onChange={() => handleChange('friday', 'afternoon')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.saturday.afternoon}
                onChange={() => handleChange('saturday', 'afternoon')}
              />
            </td>
          </tr>
          <tr>
            <td>Evening (5pm-8pm)</td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.sunday.evening}
                onChange={() => handleChange('sunday', 'evening')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.monday.evening}
                onChange={() => handleChange('monday', 'evening')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.tuesday.evening}
                onChange={() => handleChange('tuesday', 'evening')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.wednesday.evening}
                onChange={() => handleChange('wednesday', 'evening')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.thursday.evening}
                onChange={() => handleChange('thursday', 'evening')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.friday.evening}
                onChange={() => handleChange('friday', 'evening')}
              />
            </td>
            <td className={classes.availabilityHeaderCell}>
              <Checkbox
                color="primary"
                checked={values.availability.saturday.evening}
                onChange={() => handleChange('saturday', 'evening')}
              />
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  );
};

export default AvailabilityField;
