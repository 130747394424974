import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  table: {
  },
  tableCell: {
    minWidth: 200,
  },
  tableContainer: {
    width: '90%',
    overflowX: 'auto',
  },
  filters: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const ungroupedFilter = {
  property: 'ungrouped',
  name: 'Ungrouped',
  type: 'UNGROUPED',
  comparison: (registration) => {
    if (!registration.groupId) {
      return true;
    }

    return false;
  },
};

const groupedFilter = {
  property: 'grouped',
  name: 'Grouped',
  type: 'GROUPED',
  comparison: (registration) => {
    if (!registration.groupId) {
      return false;
    }

    return true;
  },
};

const FilterEditor = ({ onClose, onSave }) => {
  const classes = useStyles();

  const [filters] = useState([ungroupedFilter, groupedFilter]);
  const [newFilter, setNewFilter] = useState(ungroupedFilter);
  const [newFilterValue, setNewFilterValue] = useState('');

  const selectFilter = (filterProp) => {
    const foundFilter = filters.find((f) => f.property === filterProp);

    setNewFilter(foundFilter);
  };

  const handleSaveClick = () => {
    newFilter.value = newFilterValue;

    onSave(newFilter);
  };

  return (
    <form onSubmit={onSave} className={classes.root}>
      <DialogTitle id="form-dialog-title">Add Filter</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add a filter, select the field and enter a value.
        </DialogContentText>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-age-native-simple"> Field </InputLabel>
          <Select
            native
            value={newFilter.property}
            onChange={(e) => selectFilter(e.target.value)}
            label="term"
          >
            {
                        filters.map((f) => (
                          <option value={f.property} key={f.property}>
                            {' '}
                            {f.name}
                            {' '}
                          </option>
                        ))
}
          </Select>
        </FormControl>
        {
                newFilter.type !== 'UNGROUPED' && newFilter.type !== 'GROUPED' ? (
                  <TextField
                    id="name"
                    label="Value"
                    variant="outlined"
                    value={newFilterValue}
                    onChange={(e) => setNewFilterValue(e.target.value)}
                    fullWidth
                  />
                ) : <div />
            }

      </DialogContent>
      <DialogActions>
        <Tooltip title="Cancel without saving">
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </Tooltip>
        <Tooltip title="Add filter and close">
          <Button onClick={() => handleSaveClick()} color="primary">
            Add
          </Button>
        </Tooltip>
      </DialogActions>
    </form>
  );
};

export default FilterEditor;
