import {
  GET_ALL_FORMS_SUCCESS, ADD_FORM_SUCCESS, UPDATE_FORM_SUCCESS, DELETE_FORM_SUCCESS,
} from '../actions/formActions';

import initialState from './initialState';

const forms = (state = initialState.forms, action) => {
  switch (action.type) {
    case GET_ALL_FORMS_SUCCESS:
      return {
        ...state,
        forms: action.forms,
      };
    case ADD_FORM_SUCCESS:
      return {
        ...state,
        forms: [...state.forms, action.form],
      };
    case UPDATE_FORM_SUCCESS:
      return {
        forms: state.forms.map((form) => {
          if (form.id !== action.form.id) {
            return form;
          }

          return action.form;
        }),
      };
    case DELETE_FORM_SUCCESS:
      return {
        ...state,
        forms: state.forms.filter((f) => f.id !== action.form.id),
      };
    default:
      return state;
  }
};

export default forms;
