import { db } from "../firebase";

const getAllInvoices = () =>
  new Promise((resolve) => {
    db.collection("invoices").onSnapshot((snapshot) => {
      const allInvoices = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      resolve(allInvoices);
    });
  });

const addInvoice = (invoice) => {
  delete invoice.id;

  return new Promise((resolve) => {
    db.collection("invoices")
      .add(invoice)
      .then((i) => {
        invoice.id = i.id;

        resolve(invoice);
      });
  });
};

const addInvoices = (invoices) =>
  new Promise((resolve) => {
    const batch = db.batch();

    invoices.forEach((invoice) => {
      const ref = db.collection("invoices").doc();

      batch.set(ref, invoice);
    });

    batch.commit().then(() => resolve(invoices));
  });

const updateInvoice = (invoice) =>
  new Promise((resolve) => {
    db.collection("invoices")
      .doc(invoice.id)
      .set(invoice)
      .then(() => {
        resolve(invoice);
      });
  });

const deleteInvoice = (invoice) =>
  new Promise((resolve) => {
    db.collection("invoices")
      .doc(invoice.id)
      .delete()
      .then(() => {
        resolve(invoice);
      });
  });

const InvoiceService = {
  getAllInvoices,
  addInvoice,
  addInvoices,
  updateInvoice,
  deleteInvoice,
};

export default InvoiceService;
