import InvoiceService from '../../services/invoiceService';
import { showSnackbar } from './snackbarActions';

/*
 * Action Types
 */
export const ADD_INVOICE = 'ADD_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICES = 'ADD_INVOICES';
export const ADD_INVOICES_SUCCESS = 'ADD_INVOICES_SUCCESS';

export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';

export const GET_ALL_INVOICES = 'GET_ALL_INVOICES';
export const GET_ALL_INVOICES_SUCCESS = 'GET_ALL_INVOICES_SUCCESS';

/*
 * Action creators
 */
export const deleteInvoiceSuccess = (invoice) => ({ type: DELETE_INVOICE_SUCCESS, invoice });
export const deleteInvoice = (invoice) => (dispatch) => InvoiceService.deleteInvoice(invoice).then(() => {
  dispatch(showSnackbar('Successfully deleted invoice.'));
  dispatch(deleteInvoiceSuccess(invoice));
});

export const addInvoiceSuccess = (invoice) => ({ type: ADD_INVOICE_SUCCESS, invoice });
export const updateInvoiceSuccess = (invoice) => ({ type: UPDATE_INVOICE_SUCCESS, invoice });
export const saveInvoice = (invoice) => (dispatch) => {
  if (!invoice.id) {
    return InvoiceService.addInvoice(invoice).then((newInvoice) => {
      dispatch(showSnackbar('Successfully added invoice.'));
      dispatch(addInvoiceSuccess(newInvoice));
    }).catch((error) => {
      throw error;
    });
  }
  return InvoiceService.updateInvoice(invoice).then(() => {
    dispatch(showSnackbar('Successfully updated invoice.'));
    dispatch(updateInvoiceSuccess(invoice));
  }).catch((error) => {
    throw error;
  });
};

export const addInvoicesSuccess = (invoices) => ({ type: ADD_INVOICES_SUCCESS, invoices });
export const addInvoices = (invoices) => (dispatch) => InvoiceService.addInvoices(invoices).then((newInvoices) => {
  dispatch(showSnackbar('Successfully added invoices.'));
  dispatch(addInvoicesSuccess(newInvoices));
}).catch((error) => {
  throw error;
});

export const getAllInvoicesSuccess = (invoices) => ({ type: GET_ALL_INVOICES_SUCCESS, invoices });
export const getAllInvoices = () => (dispatch) => InvoiceService.getAllInvoices().then((invoices) => {
  dispatch(getAllInvoicesSuccess(invoices));
}).catch((error) => {
  throw error;
});
