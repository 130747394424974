import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';

import ReactCodeInput from 'react-verification-code-input';
import { Container, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '50px',
    marginBottom: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submitButton: {
    marginTop: '25px',
    borderRadius: '30px',
  },
}));

const GroupVerificationComponent = ({ history }) => {
  const classes = useStyles();

  const [value, setValue] = useState('');

  function handleChange(e) {
    setValue(e.toUpperCase());
  }

  function handleSubmit() {
    history.push(`/group/${value}`);
  }

  return (
    <Container className={classes.container}>
      <div><Typography>Enter your 6 digit class code to access your course materials.</Typography></div>
      <div><ReactCodeInput type="text" onChange={handleChange} /></div>
      { value.length === 6 ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      ) : '' }
    </Container>
  );
};

GroupVerificationComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = () => {};

const mapDispatchToProps = {};

// eslint-disable-next-line max-len
const GroupVerification = withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupVerificationComponent));

export default GroupVerification;
