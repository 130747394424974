import {
  GET_ALL_VENUES_SUCCESS, GET_ALL_VENUE_GROUPS_SUCCESS, ADD_VENUE_SUCCESS, UPDATE_VENUE_SUCCESS,
} from '../actions/venueActions';

import initialState from './initialState';

const venues = (state = initialState.venues, action) => {
  switch (action.type) {
    case GET_ALL_VENUES_SUCCESS:
      return {
        ...state,
        venues: action.venues,
      };
    case GET_ALL_VENUE_GROUPS_SUCCESS:
      return {
        ...state,
        venueGroups: action.venueGroups,
      };
    case ADD_VENUE_SUCCESS:
      return {
        ...state,
        venues: [...state.venues, action.venue],
      };
    case UPDATE_VENUE_SUCCESS:
      const foundIndex = state.venues.findIndex((venue) => venue.id === action.venue.id);

      return {
        ...state,
        venues: state.venues.splice(foundIndex, 1, action.venue),
      };
    default:
      return state;
  }
};

export default venues;
