import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { TextField, Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import { saveTerm } from '../../redux/actions/termActions';

import '../../formStyles.scss';

const AddTermComponent = ({ saveTerm, term, history }) => {
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (term) {
      setName(term.name);
      setActive(term.active);
    }
  }, [term]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const t = {
      name,
      active,
    };

    if (term) {
      t.id = term.id;
    }

    saveTerm(t).then(() => {
      history.push('/dashboard/view-terms');
    });
  };

  return (
    <main>
      <h2>Add Term</h2>
      <form onSubmit={handleSubmit}>
        <div className="formElement">
          <TextField
            id="name"
            label="Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="formElement">
          <FormControlLabel
            control={(
              <Switch
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                name="active"
                color="primary"
              />
                          )}
            label="Active"
          />
        </div>

        <Button type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
      </form>
    </main>
  );
};

AddTermComponent.propTypes = {
  saveTerm: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ terms: { terms } }, ownProps) => {
  const termId = ownProps.match.params.id;

  return {
    term: terms.find((term) => term.id === termId) || null,
  };
};

const mapDispatchToProps = {
  saveTerm,
};

const AddTerm = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTermComponent));

export default AddTerm;
