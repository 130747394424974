import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { TextField, Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputMask from 'react-input-mask';

import base64 from 'react-native-base64';

import PropTypes from 'prop-types';
import { savePerson } from '../../redux/actions/personnelActions';

import '../../formStyles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  imagePreview: {
    height: 'auto',
    width: '25vw',
  },
}));

const AddPersonComponent = ({ savePerson, person, history }) => {
  const classes = useStyles();

  const [active, setActive] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState('Teaching Artist');
  const [bio, setBio] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [image, setImage] = useState('');

  useEffect(() => {
    if (person) {
      setActive(person.active);
      setFirstName(person.firstName);
      setLastName(person.lastName);
      setTitle(person.title);
      setEmail(person.email);
      setPhone(person.phone);
      setType(person.type);
      setBio(person.bio || '');
      setImage(person.image || '');
      setUsername(person.username || '');
      setPassword(person.password ? base64.decode(person.password) : '');
    }
  }, [person]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const p = {
      firstName,
      lastName,
      title,
      email,
      phone,
      type,
      active,
      bio,
      username,
      password,
      image,
    };

    if (person) {
      p.id = person.id;
    }

    savePerson(p).then(() => history.push('/dashboard/view-teaching-artists'));
  };

  const handleEmailBlur = () => {
    if (!username) {
      setUsername(email);
    }
  };

  return (
    <main>
      <h2>Add Person</h2>
      <form onSubmit={handleSubmit} className={classes.root}>
        <div className="formElement">
          <FormControlLabel
            control={(
              <Switch
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                name="active"
                color="primary"
              />
                          )}
            label="Active"
          />
        </div>
        <div className="formElement">
          <TextField
            id="firstName"
            label="First Name"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="formElement">
          <TextField
            id="lastName"
            label="Last Name"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="formElement">
          <TextField
            id="title"
            label="Title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="formElement">
          <TextField
            id="email"
            label="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => handleEmailBlur()}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="formElement">
          <InputMask
            mask="(999)999-9999"
            value={phone}
            disabled={false}
            maskChar=" "
            onChange={(e) => setPhone(e.target.value)}
          >
            {() => <TextField fullWidth label="Phone" variant="outlined" />}
          </InputMask>

        </div>
        <div className="formElement">
          <TextField
            id="userName"
            label="Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="formElement">
          <TextField
            id="password"
            label="Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </div>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
          <Select
            native
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="active"
            inputProps={{
              name: 'type',
              id: 'outlined-age-native-simple',
            }}
          >
            <option value="Teaching Artist" key="1">Teaching Artist</option>
            <option value="Admin" key="2">Admin</option>
          </Select>
        </FormControl>

        <TextField
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          label="Bio"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
        />

        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
        />

        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="default"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Photo Upload
          </Button>
        </label>
        <br />

        {image ? (<img src={image} alt="person_photo" className={classes.imagePreview} />) : (<div />)}

        <Button type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
      </form>
    </main>
  );
};

AddPersonComponent.propTypes = {
  savePerson: PropTypes.func.isRequired,
  person: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ personnel }, ownProps) => {
  const personId = ownProps.match.params.id;

  return {
    person: personnel.personnel.find((person) => person.id === personId) || null,
  };
};

const mapDispatchToProps = {
  savePerson,
};

const AddPerson = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPersonComponent));

export default AddPerson;
