import FormService from '../../services/formService';
import { showSnackbar } from './snackbarActions';

import { ERROR } from '../../snackbar/successSnackbar';
import { DEPENDENCY } from '../../services/errors';

/*
 * Action Types
 */
export const ADD_FORM = 'ADD_FORM';
export const ADD_FORM_SUCCESS = 'ADD_FORM_SUCCESS';
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';

export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS';

export const GET_ALL_FORMS = 'GET_ALL_FORMS';
export const GET_ALL_FORMS_SUCCESS = 'GET_ALL_FORMS_SUCCESS';

/*
 * Action creators
 */
export const addFormSuccess = (form) => ({ type: ADD_FORM_SUCCESS, form });
export const updateFormSuccess = (form) => ({ type: UPDATE_FORM_SUCCESS, form });
export const saveForm = (form) => (dispatch) => {
  if (!form.id) {
    return FormService.addForm(form).then((newForm) => {
      dispatch(showSnackbar('Successfully added form.'));
      dispatch(addFormSuccess(newForm));
    }).catch((error) => {
      throw error;
    });
  }
  return FormService.updateForm(form).then(() => {
    dispatch(showSnackbar('Successfully updated form.'));
    dispatch(updateFormSuccess(form));
  }).catch((error) => {
    throw error;
  });
};

export const deleteFormSuccess = (form) => ({ type: DELETE_FORM_SUCCESS, form });
export const deleteForm = (form) => (dispatch) => FormService.deleteForm(form).then(() => {
  dispatch(showSnackbar('Successfully deleted form.'));
  dispatch(deleteFormSuccess(form));
}).catch((error) => {
  if (error.type === DEPENDENCY) {
    dispatch(showSnackbar('Can not delete form. Make sure no other courses are dependent on this form.', ERROR));
  }
  // throw error
});

export const getAllFormsSuccess = (forms) => ({ type: GET_ALL_FORMS_SUCCESS, forms });
export const getAllForms = () => (dispatch) => FormService.getAllForms().then((forms) => {
  dispatch(getAllFormsSuccess(forms));
}).catch((error) => {
  throw error;
});
