import {
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_OUT_SUCCESS,
} from '../actions/authActions';

import initialState from './initialState';

const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        currentUser: action.user,
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };
    default:
      return state;
  }
};

export default auth;
