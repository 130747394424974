import GroupService from "../../services/groupService";
import { addInvoices } from "./invoiceActions";

import { showSnackbar } from "./snackbarActions";

import { ERROR } from "../../snackbar/successSnackbar";
import { DEPENDENCY } from "../../services/errors";

import { saveRegistration } from "./registrationActions";

/*
 * Action Types
 */
export const ADD_GROUP = "ADD_GROUP";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";

export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS";
export const INVOICE_GROUP = "INVOICE_GROUP";

/*
 * Action creators
 */
export const deleteGroupSuccess = (group) => ({
  type: DELETE_GROUP_SUCCESS,
  group,
});
export const deleteGroup = (group) => (dispatch) =>
  GroupService.deleteGroup(group)
    .then(() => {
      dispatch(showSnackbar("Successfully deleted group."));
      dispatch(deleteGroupSuccess(group));
    })
    .catch((error) => {
      if (error.type === DEPENDENCY) {
        dispatch(
          showSnackbar(
            "Can not delete group. Make sure no registrations are dependent on this group.",
            ERROR
          )
        );
      }
    });

export const addGroupSuccess = (group) => ({ type: ADD_GROUP_SUCCESS, group });
export const updateGroupSuccess = (group) => ({
  type: UPDATE_GROUP_SUCCESS,
  group,
});
export const saveGroup = (group, groupMembers = [], removedMembers = []) => (
  dispatch
) => {
  if (group.id === -1) {
    return GroupService.addGroup(group)
      .then((newGroup) => {
        groupMembers.forEach((groupMember) => {
          groupMember.groupId = newGroup.id;
          dispatch(saveRegistration(groupMember));
        });

        dispatch(showSnackbar("Successfully added group."));
        dispatch(addGroupSuccess(newGroup));
      })
      .catch((error) => {
        throw error;
      });
  }

  removedMembers.forEach((groupMember) => {
    delete groupMember.groupId;

    dispatch(saveRegistration(groupMember));
  });

  return GroupService.updateGroup(group)
    .then((updatedGroup) => {
      groupMembers.forEach((groupMember) => {
        groupMember.groupId = updatedGroup.id;
        dispatch(saveRegistration(groupMember));
      });

      dispatch(showSnackbar("Successfully updated group."));
      dispatch(updateGroupSuccess(updatedGroup));
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllGroupsSuccess = (groups) => ({
  type: GET_ALL_GROUPS_SUCCESS,
  groups,
});
export const getAllGroups = () => (dispatch) =>
  GroupService.getAllGroups()
    .then((groups) => {
      dispatch(getAllGroupsSuccess(groups));
    })
    .catch((error) => {
      throw error;
    });

export const invoiceGroup = (group) => (dispatch) =>
  GroupService.invoiceGroup(group)
    .then((invoices) => {
      dispatch(addInvoices(invoices));
    })
    .catch((error) => {
      if (error.type === DEPENDENCY) {
        dispatch(
          showSnackbar(
            "Can not delete group. Make sure no registrations are dependent on this group.",
            ERROR
          )
        );
      }
    });
