import React, { useState } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Button } from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const borderRadius = '30px';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  subtitle: {
    fontSize: '32px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  statement: {
    fontWeight: 600,
    padding: '30px 0 30px 0',
  },
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
  button: {
    borderRadius,
    padding: '20px 30px 20px 30px',
    marginTop: '30px',
  },
  linkText: {
    textDecoration: 'none',
  },
}));

function getSteps() {
  return ['Pick a subject', 'Pick a course', 'Register', 'Expectations', 'Finished'];
}

const RegistrationConfirmationComponent = () => {
  const classes = useStyles();

  const [activeStep] = useState(4);
  const steps = getSteps();

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.root}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container className={classes.container}>
        <Typography className={classes.headline}>Registration Confirmed</Typography>
        <Typography>
          Your registration is confirmed. You will be contacted soon with further instructions
        </Typography>

        <Link color="inherit" to="/" className={classes.linkText}>
          <Button variant="contained" color="primary" className={classes.button} fullWidth> Return Home </Button>
        </Link>
      </Container>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

// eslint-disable-next-line max-len
const RegistrationConfirmation = withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationConfirmationComponent));

export default RegistrationConfirmation;
