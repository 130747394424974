import {
  GET_ALL_REGISTRATIONS_SUCCESS,
  ADD_REGISTRATION_SUCCESS,
  UPDATE_REGISTRATION_SUCCESS,
  ADD_REGISTRATION_FILTER,
  DELETE_REGISTRATION_FILTER,
  SAVE_PROGRESS,
} from '../actions/registrationActions';

import initialState from './initialState';

const registrations = (state = initialState.registrations, action) => {
  switch (action.type) {
    case GET_ALL_REGISTRATIONS_SUCCESS:
      return {
        ...state,
        registrations: action.registrations,
      };
    case ADD_REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationProgress: null,
        registrations: [...state.registrations, action.registration],
      };
    case UPDATE_REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationProgress: null,
        registrations: state.registrations.map((r) => {
          if (r.id !== action.registration.id) {
            return r;
          }

          return action.registration;
        }),
      };
    case ADD_REGISTRATION_FILTER:
      return {
        ...state,
        filters: [...state.filters, action.filter],
      };
    case DELETE_REGISTRATION_FILTER:
      return {
        ...state,
        filters: state.filters.filter((f) => f !== action.filter),
      };
    case SAVE_PROGRESS:
      return {
        ...state,
        registrationProgress: action.registration,
      };
    default:
      return state;
  }
};

export default registrations;
