import {
  GET_ALL_TERMS_SUCCESS, ADD_TERM_SUCCESS, UPDATE_TERM_SUCCESS, DELETE_TERM_SUCCESS,
} from '../actions/termActions';

import initialState from './initialState';

const terms = (state = initialState.terms, action) => {
  switch (action.type) {
    case GET_ALL_TERMS_SUCCESS:
      return {
        ...state,
        terms: action.terms,
      };
    case ADD_TERM_SUCCESS:
      return {
        ...state,
        terms: [...state.terms, action.term],
      };
    case UPDATE_TERM_SUCCESS:
      return {
        terms: state.terms.map((term) => {
          if (term.id !== action.term.id) {
            return term;
          }

          return action.term;
        }),
      };
    case DELETE_TERM_SUCCESS:
      return {
        ...state,
        terms: state.terms.filter((t) => t.id !== action.term.id),
      };
    default:
      return state;
  }
};

export default terms;
