import base64 from 'react-native-base64';
import { db, storage } from '../firebase';
import { DEPENDENCY } from './errors';

import GroupService from './groupService';

const getAllPersonnel = () => new Promise((resolve, reject) => {
  db.collection('personnel').onSnapshot((snapshot) => {
    const allPersonnel = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    resolve(allPersonnel);
  });
});

const addPerson = (person) => {
  if (person.image && typeof (person.image) !== 'string') {
    return new Promise((resolve, reject) => {
      const imageName = person.image.name;

      const uploadTask = storage.ref(`/images/personnel/${imageName}`).put(person.image);
      uploadTask.on('state_changed', (snapShot) => {
        // takes a snap shot of the process as it is happening
        console.log(snapShot);
      }, (err) => {
        // catches the errors
        console.log(err);
      }, () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage.ref('images/personnel').child(imageName).getDownloadURL()
          .then((fireBaseUrl) => {
            person.image = fireBaseUrl;

            person.password = base64.encode(person.password);

            db.collection('personnel')
              .add(person)
              .then((t) => {
                person.id = t.id;

                resolve(person);
              });
          });
      });
    });
  }
  return new Promise((resolve, reject) => {
    person.password = base64.encode(person.password);

    db.collection('personnel')
      .add(person)
      .then((t) => {
        person.id = t.id;

        resolve(person);
      });
  });
};

const updatePerson = (person) => {
  if (person.image && typeof (person.image) !== 'string') {
    return new Promise((resolve, reject) => {
      const imageName = person.image.name;

      const uploadTask = storage.ref(`/images/personnel/${imageName}`).put(person.image);
      uploadTask.on('state_changed', (snapShot) => {
        // takes a snap shot of the process as it is happening
        console.log(snapShot);
      }, (err) => {
        // catches the errors
        console.log(err);
      }, () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage.ref('images/personnel').child(imageName).getDownloadURL()
          .then((fireBaseUrl) => {
            person.image = fireBaseUrl;

            person.password = base64.encode(person.password);

            db.collection('personnel')
              .doc(person.id)
              .set(person)
              .then((t) => {
                resolve(person);
              });
          });
      });
    });
  }
  return new Promise((resolve) => {
    person.password = base64.encode(person.password);

    db.collection('personnel')
      .doc(person.id)
      .set(person)
      .then((t) => {
        resolve(person);
      });
  });
};

const deletePerson = (person) => new Promise((resolve, reject) => {
  GroupService.getGroupsByPerson(person).then((groups) => {
    // Ensure there are no groups associated with the person
    if (groups.length > 0) {
      reject({ type: DEPENDENCY, groups });
    } else {
      db.collection('personnel')
        .doc(person.id)
        .delete()
        .then(() => {
          resolve(person);
        });
    }
  });
});

const PersonnelService = {
  getAllPersonnel,
  addPerson,
  updatePerson,
  deletePerson,
};

export default PersonnelService;
