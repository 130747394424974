import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  IconButton,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SaveIcon from '@material-ui/icons/Save';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import {
  deleteCourse as deleteCourseAction,
  updateOrder as updateOrderAction,
} from '../../redux/actions/courseActions';

const useStyles = makeStyles((theme) => ({
  controls: {
    marginLeft: '20px',
  },
  termFilter: {
    marginLeft: '20px',
  },
}));

const ViewCoursesComponent = ({
  courses,
  terms,
  deleteCourse,
  updateOrder,
  history,
}) => {
  const classes = useStyles();

  const [term, setTerm] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [orderDirty, setOrderDirty] = useState(false);

  function editCourse(course) {
    history.push(`/dashboard/add-course/${course.id}`);
  }

  function handleDelete(course) {
    deleteCourse(course);
  }

  const [gridState, setGridState] = useState({
    columns: [
      {
        rowDrag: true,
        width: 25,
      },
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Program',
        field: 'program',
      },
      {
        headerName: 'Active',
        field: 'active',
      },
      {
        headerName: 'Price',
        field: 'price',
      },
      {
        cellRendererFramework: (params) => (
          <ListItemButtons
            course={params.data}
            editCourse={(c) => editCourse(c)}
            deleteCourse={(d) => handleDelete(d)}
          />
        ),
      },
    ],
    rows: [],
  });

  function addCourse() {
    history.push('/dashboard/add-course');
  }

  function exportAsCSV() {
    gridApi.exportDataAsCsv();
  }

  function onRowDragEnd(e) {
    const draggedCourse = e.node.data;
    const { overIndex } = e;

    const needsToMove = draggedCourse.index !== overIndex;

    if (needsToMove) {
      setOrderDirty(true);

      const workingRows = gridState.rows.filter((r) => r !== e.node.data);

      const inserted = [
        ...workingRows.slice(0, e.overIndex),
        draggedCourse,
        ...workingRows.slice(e.overIndex),
      ];
      inserted.forEach((course, index) => {
        course.index = index;
      });

      inserted.sort((a, b) => (a.index > b.index ? 1 : -1));

      gridState.rows = inserted.concat();

      e.api.setRowData(gridState.rows);
    }
  }

  function saveListChanges() {
    updateOrder(gridState.rows);
  }

  useEffect(() => {
    console.log('terms:', terms);

    if (!term && terms) {
      console.log('termcheck', terms, terms.length - 1);
      const lastTerm = terms[terms.length - 1];

      if (lastTerm) {
        setTerm(terms[terms.length - 1].id);
      }
      return;
    }

    const c = courses.filter((course) => {
      return course.term.id === term;
    });

    setGridState({
      ...gridState,
      rows: c,
    });
  }, [courses, term]);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  return (
    <main>
      <Box className={classes.controls}>
        <h2>View Courses</h2>

        <ButtonGroup>
          <Tooltip title="Add new course">
            <Button
              variant="contained"
              color="primary"
              onClick={() => addCourse()}
            >
              <AddIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Export as csv">
            <Button color="primary" onClick={() => exportAsCSV()}>
              <ImportExportIcon />
            </Button>
          </Tooltip>
          {orderDirty ? (
            <Tooltip title="Save changes">
              <Button color="primary" onClick={() => saveListChanges()}>
                <SaveIcon />
              </Button>
            </Tooltip>
          ) : (
            <Button color="primary" disabled onClick={() => saveListChanges()}>
              <SaveIcon />
            </Button>
          )}
        </ButtonGroup>

        <FormControl variant="outlined" className={classes.termFilter} required>
          <InputLabel htmlFor="outlined-age-native-simple"> Term </InputLabel>
          <Select
            native
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            label="term"
            inputProps={{
              name: 'term',
              id: 'outlined-age-native-simple',
            }}
          >
            {terms.map((term) => (
              <option value={term.id} key={term.id}>
                {' '}
                {term.name}{' '}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <div
        className="ag-theme-material"
        style={{
          height: '60vh',
          width: '100%',
        }}
      >
        <AgGridReact
          columnDefs={gridState.columns}
          rowData={gridState.rows}
          rowSelection="multiple"
          rowDragManaged
          onGridReady={(params) => setGridApi(params.api)}
          onRowDragEnd={(e) => onRowDragEnd(e)}
        />
      </div>
    </main>
  );
};

ViewCoursesComponent.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  terms: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteCourse: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const ListItemButtons = ({ course, editCourse, deleteCourse }) => {
  const [deleteMode, setDeleteMode] = useState(false);

  function handleDelete() {
    deleteCourse(course);
    setDeleteMode(false);
  }

  return (
    <span>
      {deleteMode ? (
        <>
          <span>Delete this course?</span>
          <Tooltip title="Confirm delete">
            <IconButton onClick={() => handleDelete()}>
              <CheckIcon color="secondary" />
            </IconButton>
          </Tooltip>{' '}
          <Tooltip title="Don't delete">
            <IconButton onClick={() => setDeleteMode(false)}>
              <CancelIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title="Edit course">
            <IconButton onClick={() => editCourse(course)}>
              <EditIcon />
            </IconButton>
          </Tooltip>{' '}
          <Tooltip title="Delete course">
            <IconButton onClick={() => setDeleteMode(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </span>
  );
};

ListItemButtons.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  editCourse: PropTypes.func.isRequired,
  deleteCourse: PropTypes.func.isRequired,
};

const mapStateToProps = ({ courses: { courses }, terms: { terms } }) => ({
  courses,
  terms,
});

const mapDispatchToProps = {
  deleteCourse: deleteCourseAction,
  updateOrder: updateOrderAction,
};

const ViewCourses = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewCoursesComponent)
);

export default ViewCourses;
