import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';

import EmailIcon from '@material-ui/icons/Email';
import AttachmentIcon from '@material-ui/icons/Attachment';

import {
  Container, List, ListItem, Paper, Box, Button,
} from '@material-ui/core';

const borderRadius = '10px';

function makeGroupStyles(heroImage) {
  return makeStyles(() => ({
    headline: {
      fontSize: '64px',
      fontWeight: 600,
    },
    post: {
      backgroundColor: '#f2f2f2',

      display: 'flex',
      flexDirection: 'column',
      borderRadius: `${borderRadius} ${borderRadius} ${borderRadius} ${borderRadius}`,
      padding: '20px',
      width: '100vw',
    },
    missionHeroImage: {
      width: '100%',
      height: '200px',
      backgroundImage: `url(${heroImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'row',
    },
    controls: {
      marginLeft: '20px',
    },
  }));
}

const GroupComponent = ({ group, teacher }) => {
  const useStyles = makeGroupStyles(group ? group.photo : '');
  const classes = useStyles();

  function getDateString(date) {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(date);
  }

  function emailTeacher() {
    window.location.href = `mailto:${teacher.email}?subject=${group.name}`;
  }

  function viewAttachment(attachment) {
    window.open(attachment.path);
  }

  function getGroupContent() {
    if (!group.posts) group.posts = [];

    return (
      <Box>
        <Box className={classes.controls}>
          <h2>{ group.name }</h2>
          { teacher ? (
            <Button color="primary" onClick={() => emailTeacher()} startIcon={<EmailIcon />}>
              Email
              {' '}
              {teacher.firstName}
              {' '}
              {teacher.lastName}
            </Button>
          ) : '' }
        </Box>
        <List>
          { group.posts.map((post) => (
            <ListItem>
              <Paper className={classes.post}>
                <div><i>{ post.postAuthor }</i></div>
                <div><i>{ getDateString(post.postDate) }</i></div>
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
                { post.attachments && post.attachments.length > 0 ? (
                  post.attachments.map((attachment) => <Button color="primary" onClick={() => viewAttachment(attachment)} startIcon={<AttachmentIcon />}>{attachment.name}</Button>)
                ) : ''}
              </Paper>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  function getNoGroup() {
    return <Container>No group found.</Container>;
  }

  return group ? getGroupContent() : getNoGroup();
};

GroupComponent.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  teacher: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ groups: { groups }, personnel: { personnel } }, ownProps) => {
  const code = ownProps.match.params ? ownProps.match.params.code : null;

  const found = groups ? groups.find((g) => g.code === code) : {};

  const teacher = found && personnel ? personnel.find((p) => p.id === found.teacherId) : {};

  console.log('found=', found, teacher);

  return {
    group: found || {},
    teacher: teacher || {},
  };
};

const mapDispatchToProps = {

};

const Group = withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupComponent));

export default Group;
