import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { makeStyles } from '@material-ui/core/styles';
/*
import Band from './music';
import VisualArt from './visualArt';
import Dance from './dance';
import Theater from './theater';
import MediaArts from './mediaArts';
*/
import GoldenOak from './goldenOak';
import SulphurSprings from './sulphurSprings';
import Pinetree from './pinetree';
import Mitchell from './mitchell';
import FairOaksRanch from './fairOaksRanch';
import ValleyView from './valleyView';
import CanyonSprings from './canyonSprings';
import LeonaCox from './leonaCox';
import MintCanyon from './mintCanyon';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
}));

function getSteps() {
  return [
    'Pick a subject',
    'Pick a course',
    'Register',
    'Expectations',
    'Finished',
  ];
}

const Programs = () => {
  const classes = useStyles();

  const [activeStep] = useState(0);
  const steps = getSteps();

  return (
    <>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.root}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.container}>
        <Typography className={classes.headline}>Pick Your School</Typography>
        {/*
        <Band />
        <VisualArt />
        <Dance />
        <Theater />
        <MediaArts />
        */}

        <CanyonSprings />
        <FairOaksRanch />
        <GoldenOak />
        <LeonaCox />
        <MintCanyon />
        <Mitchell />
        <Pinetree />
        <SulphurSprings />
        <ValleyView />
      </div>
    </>
  );
};

export default Programs;
