import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles, Typography } from '@material-ui/core';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const borderRadius = '30px';

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    fontWeight: 600,

    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5vw',
    },
  },
  content: {
    width: '100%',
    fontWeight: 400,
    fontSize: '14px',
  },
  programInfo: {
    height: '100%',
  },
  programDetails: {
    backgroundColor: '#f2f2f2',
    borderRadius: `0px 0px ${borderRadius} ${borderRadius}`,

    display: 'flex',
    flexDirection: 'column',

    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px',
    },
  },
  heroImage: {
    width: '100%',
    height: 'auto',
    borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  gridList: {
    width: '100%',
    transform: 'translateZ(0)',
  },
  linkText: {
    textDecoration: 'none',
  },
}));

const TeachingArtistsComponent = ({ personnel, width }) => {
  const classes = useStyles();

  const getGridListCols = () => {
    if (isWidthUp('xl', width)) {
      return 4;
    }

    if (isWidthUp('lg', width)) {
      return 3;
    }

    if (isWidthUp('md', width)) {
      return 2;
    }

    return 1;
  };

  const getBackgroundImage = (person) => {
    if (person.image) {
      return person.image;
    }
    const photoFile = `${person.firstName.toLowerCase() + person.lastName.toLowerCase()}.jpg`;
    const imgUrl = `/assets/images/profilepictures/${photoFile}`;

    return imgUrl;
  };

  return (
    <>
      <Typography className={classes.headline}>Teaching Artists</Typography>
      <GridList cellHeight="auto" className={classes.gridList} cols={getGridListCols()}>
        {personnel.map((person) => (
          <GridListTile key={person.id} className={classes.programInfo}>

            <img src={getBackgroundImage(person)} alt="profile pic" className={classes.heroImage} />
            <div className={classes.programDetails}>
              <Typography className={classes.title}>
                {person.firstName}
                {' '}
                {person.lastName}
              </Typography>
              <Typography className={classes.content}>{person.title}</Typography>
              <Link color="inherit" to={`/person/${person.id}`} className={classes.linkText}>
                <Button color="primary">More Info</Button>
              </Link>
            </div>

          </GridListTile>
        ))}
      </GridList>
    </>
  );
};

const mapStateToProps = ({ personnel: { personnel } }) => ({
  personnel: personnel.filter((person) => person.active),
});

const mapDispatchToProps = {

};

const TeachingArtists = withWidth()(connect(mapStateToProps, mapDispatchToProps)(TeachingArtistsComponent));

export default TeachingArtists;
