/* eslint-disable max-len */
import React from 'react';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
  title: {
    width: '100%',
    fontWeight: 600,
    fontSize: '48px',
  },
  subTitle: {
    width: '100%',
    fontWeight: 600,
    fontSize: '24px',
  },
  content: {
    width: '100%',
    fontWeight: 400,
    fontSize: '14px',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 30px 0',
  },
  missionHeroImage: {
    width: '100%',
    height: '200px',
    backgroundImage: 'url("/assets/images/mission_banner.jpeg")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'row',
  },
  historyHeroImage: {
    marginTop: '50px',
    width: '100%',
    height: '500px',
    backgroundImage: 'url("/assets/images/history_banner.jpeg")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  space: {
    width: '100%',
  },
  mission: {
    width: '100%',
    padding: '20px',
    background: 'rgba(0, 0, 0, 0.1)',
  },

}));

const Mission = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.missionHeroImage} />
      <div className={classes.container}>
        <Typography className={classes.headline}>Our Mission</Typography>
        <Typography>
          Cultivating Creative Minds is a non-profit organization with the mission to promote creative programs for young people, partner with schools to organize and execute enrichment programs that promote creative thinking, and to help schools find and sustain funding for creative programs.
        </Typography>
        <div className={classes.historyHeroImage} />
        <Typography className={classes.headline}>Our History</Typography>

        <Typography>For the last 20 years, California public elementary schools generally lacked full funding and support for school district-wide coordinated arts education programs. Unless parents, PTA’s and local community organizations donated money, many schools could not afford to hire teachers for general music, visual art, dance, or theater. </Typography>
        <br />
        <Typography>In 2001, four local principals joined together with the PTA and started an after-school band program for 4th, 5th, and 6th graders. In 2003, they hired Roxanne Jeppesen to teach the percussion classes and organize a joint concert at the end of each year.</Typography>
        <br />
        <Typography>Roxanne Jeppesen founded Cultivating Creative Minds in 2010 to combine all band classes under one umbrella organization. Within a few years, Cultivating Creative Minds added honor band, choir, guitar, keyboarding, and violin classes. From 2010-2019, the after-school programs continued to grow and spread to more schools in the area.</Typography>
        <br />
        <Typography>Local principals and PTAs then turned their attention to offering arts education during the regular school day. Jane D’Anna, principal of Pinetree School, worked with Cultivating Creative Minds to develop and pilot an arts enrichment rotation. Jane invited 3-4 professional artists into the elementary school to teach all four arts education content areas, including general music, visual art, theater, and dance. This program quickly spread to every school in the District. By 2019, Cultivating Creative Minds helped to coordinate and schedule arts enrichment rotations for 14 elementary schools in the area. </Typography>
        <br />
        <Typography>In 2020, the pandemic has challenged us to be even more creative and revamp our approach. We are improvising, exploring, and discovering new ways to be creative. We are learning to utilize advances in our technology tools and finding new ways to expose our students to the creative process. I am especially happy that school districts are investing in visual and performing arts curriculums for all students! </Typography>

      </div>
    </>
  );
};

export default Mission;
