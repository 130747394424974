import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  IconButton,
  Button,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import { deleteForm } from "../../redux/actions/formActions";

const useStyles = makeStyles((theme) => ({
  controls: {
    marginLeft: "20px",
  },
}));

const ViewFormsComponent = ({ forms, deleteForm, history }) => {
  const classes = useStyles();

  const addForm = () => {
    history.push("/dashboard/add-form");
  };

  const editForm = (form) => {
    history.push(`/dashboard/add-form/${form.id}`);
  };

  const handleDelete = (term) => {
    deleteForm(term);
  };

  return (
    <main>
      <Box className={classes.controls}>
        <h2>View Forms</h2>

        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={() => addForm()}
        >
          Add Form
        </Button>
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {forms.map((form) => (
              <ListItem
                key={form.id}
                form={form}
                editForm={editForm}
                deleteForm={handleDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
};

const ListItem = ({ form, editForm, deleteForm }) => {
  const [deleteMode, setDeleteMode] = useState(false);

  const handleDelete = () => {
    deleteForm(form);
    setDeleteMode(false);
  };

  return (
    <TableRow>
      <TableCell>{form.name}</TableCell>
      <TableCell align="right">
        {deleteMode ? (
          <>
            <Typography color="secondary">
              Are you sure you'd like to delete this term?
            </Typography>
            <Tooltip title="Confirm delete">
              <IconButton onClick={() => handleDelete()}>
                <CheckIcon color="secondary" />
              </IconButton>
            </Tooltip>{" "}
            &nbsp;
            <Tooltip title="Don't delete">
              <IconButton onClick={() => setDeleteMode(false)}>
                <CancelIcon color="secondary" />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        ) : (
          <>
            <Tooltip title="Edit Form">
              <IconButton onClick={() => editForm(form)}>
                <EditIcon />
              </IconButton>
            </Tooltip>{" "}
            &nbsp;
            <Tooltip title="Delete form">
              <IconButton onClick={() => setDeleteMode(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = ({ forms: { forms } }, ownProps) => ({
  forms,
});

const mapDispatchToProps = {
  deleteForm,
};

const ViewForms = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewFormsComponent)
);

export default ViewForms;
