import CourseService from '../../services/courseService';
import { showSnackbar } from './snackbarActions';

import { ERROR } from '../../snackbar/successSnackbar';
import { DEPENDENCY } from '../../services/errors';

/*
 * Action Types
 */
export const ADD_COURSE = 'ADD_COURSE';
export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';

export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';

export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_ALL_COURSES_SUCCESS = 'GET_ALL_COURSES_SUCCESS';

export const UPDATE_ORDER = 'UPDATE_ORDER';

/*
 * Action creators
 */
export const deleteCourseSuccess = (course) => ({ type: DELETE_COURSE_SUCCESS, course });
export const deleteCourse = (course) => (dispatch) => CourseService.deleteCourse(course).then(() => {
  dispatch(showSnackbar('Successfully deleted course.'));
  dispatch(deleteCourseSuccess(course));
}).catch((error) => {
  if (error.type === DEPENDENCY) {
    dispatch(showSnackbar('Can not delete course. Make sure no registrations or groups are dependent on this term.', ERROR));
  }
});

export const addCourseSuccess = (course) => ({ type: ADD_COURSE_SUCCESS, course });
export const updateCourseSuccess = (course) => ({ type: UPDATE_COURSE_SUCCESS, course });
export const saveCourse = (course) => (dispatch) => {
  if (course.id === -1) {
    return CourseService.addCourse(course).then((newCourse) => {
      dispatch(showSnackbar('Successfully added course.'));
      dispatch(addCourseSuccess(newCourse));
    }).catch((error) => {
      throw error;
    });
  }
  return CourseService.updateCourse(course).then((updatedCourse) => {
    dispatch(showSnackbar('Successfully updated course.'));
    dispatch(updateCourseSuccess(updatedCourse));
  }).catch((error) => {
    throw error;
  });
};

export const getAllCoursesSuccess = (courses) => ({ type: GET_ALL_COURSES_SUCCESS, courses });
export const getAllCourses = () => (dispatch) => CourseService.getAllCourses().then((courses) => {
  dispatch(getAllCoursesSuccess(courses));
}).catch((error) => {
  throw error;
});

export const updateOrder = (courses) => (dispatch) => CourseService.updateCourseOrder(courses).then((courses) => {
  dispatch(getAllCoursesSuccess(courses));
}).catch((error) => {
  throw error;
});
