import React from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import './home.scss';
import { Typography } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9f9fa',
    width: '100%',
    padding: '30px 10px 30px 10px',
  },
  title: {
    width: '100%',
    fontWeight: 600,
    fontSize: '48px',
  },
  subTitle: {
    width: '100%',
    fontWeight: 600,
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '25px',
  },
  content: {
    width: '100%',
    fontWeight: 400,
    fontSize: '14px',
    textAlign: 'center',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 50px 0 50px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 130px 0 130px',
    },
  },
  headline: {
    fontSize: '64px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '30px 0 0 0',
  },
  logoContainer: {
    padding: '40px 40px 0px 40px',
    background: 'rgba(1, 0, 0, .7)',
    display: 'block',
  },
  logo: {
    width: '40vw',
    height: 'auto',
  },
  linkText: {
    textDecoration: 'none',
  },
  listIntro: {
    margin: '30px 0 10px 0',
  },
}));

function getSteps() {
  return [
    'Pick your school',
    'Pick a course',
    'Register',
    'Small Group Placement',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Pick your child's school.";
    case 1:
      return 'Browse and select a course.';
    case 2:
      return 'Fill out the registration form for the selected course. Note that no payment is due at this time.';
    case 3:
      return 'Once we have placed your child in a group, your professional teaching artist will contact you.';
    case 4:
      return 'If any payment is due, you will then receive an invoice from us for the course. Stripe.com will handle all credit card processing. Follow the link in your invoice email to pay for your class.';
    default:
      return 'Unknown step';
  }
}

const HomeComponent = () => {
  const classes = useStyles();

  const steps = getSteps();

  return (
    <>
      <div className="homeHeroImage">
        <div className="heroText">
          <div className={classes.logoContainer}>
            <img
              src="/assets/images/logo_small.png"
              alt="logo"
              className={classes.logo}
            />
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <Typography className={classes.headline}>
          How Does This Work?
        </Typography>

        <Typography className={classes.listIntro}>
          The process to register for classes at Cultivating Creative Minds is
          simple:
        </Typography>

        <Stepper orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label} active>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Link to="programs" className={classes.linkText}>
          <Button variant="contained" color="primary" fullWidth>
            Ready to begin? Register Now!
          </Button>
        </Link>
      </div>
    </>
  );
};

const Home = () => <HomeComponent />;

export default Home;
